import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { GraphService } from "./GraphService";
import {
    Stack,
    IStackTokens
} from 'office-ui-fabric-react';
const config = require("./config.json");
// const resource = require("../LangFiles/resource.json");
import * as Msal from "msal";
import * as moment from 'moment';


const sectionStackTokens: IStackTokens = { childrenGap: 30 };

export interface MessagesProps { authToken: string, lang: string };
export interface MessagesState { messages?: any[], messagesAccess: boolean };

export class Messages extends React.Component<MessagesProps, MessagesState> {
    private msalConfig: Msal.Configuration;
    private userAgentApplication: Msal.UserAgentApplication;

    constructor(props: MessagesProps) {
        super(props);
        this.state = {
            messages: [],
            messagesAccess: false
        }

        this.msalConfig = {
            auth: {
                clientId: config.appId,
                authority: config.authority
            }
        }
    }

    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="card bg-swpStyle-light">
                            <div className="card-header text-center bg-swpStyle-dark swpStyle-CardHeader-Msg">
                                <h6 className="swp-CardHeader-Text">{resource.str_HeaderMessagesAndWarnings}</h6>
                            </div>
                            <div className="card-body">
                                {this.state.messagesAccess ? <Stack horizontal horizontalAlign="center" wrap tokens={sectionStackTokens}>
                                    {
                                        this.state.messages.map((message: any) => {
                                            var messageBodyClass = "swpStyle-warning-body";
                                            var messageHeaderClass = "swpStyle-warning-header"

                                            if (message.fields.Kategoria == resource.str_messageCategoryWarning || message.fields.KategoriaEN == resource.str_messageCategoryWarning) {
                                                messageBodyClass = "swpStyle-warning-body";
                                                messageHeaderClass = "swpStyle-warning-header"
                                            } else {
                                                messageBodyClass = "swpStyle-message-body";
                                                messageHeaderClass = "swpStyle-message-header"
                                            }

                                            return (
                                                <Card className={messageBodyClass}>
                                                    <Card.Header className={`${messageHeaderClass} swpStyle-Header-Text`} as="h6">{this.props.lang == "PL" ? message.fields.Kategoria : message.fields.KategoriaEN}</Card.Header>
                                                    <Card.Body className="swpStyle-bg-light">
                                                        <Card.Title className="swpStyle-General-Text" as="h6">{resource.str_LblMsgPublishDate} {this.formatDateTime(message.fields.DataPublikacji)}</Card.Title>
                                                        <Card.Text className="swpStyle-General-Text">{this.props.lang == "PL" ? message.fields.TrescKomunikatu : message.fields.TrescKomunikatuEN}</Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            );
                                        })
                                    }
                                    </Stack> :
                                    <div>{resource.str_MsgNoNewsAccess}</div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    async componentDidMount() {
        config.DEBUG && console.log("Messages component did mount");
        var graphService = new GraphService(this.props.authToken, this.userAgentApplication);

        await graphService.getNewsItems().then(
            result => {
                config.DEBUG && console.log(result);
                if (result.error == undefined) {
                    config.DEBUG && console.log("--- No error loading messages");
                    this.setState({
                        messagesAccess: true,
                        messages: result.value
                    })
                } else {
                    config.DEBUG && console.log("--- Messages not loaded ---");
                    this.setState({
                        messagesAccess: false
                    })
                }

            }
        );
    }

    formatDateTime(dateTime: string) {
        return moment.utc(dateTime).local().format('DD.MM.YYYY');
    }

}