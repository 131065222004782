//------------------------------------ Description ------------------------------------------------------------
//This form allows Operators to rename all objects (folders, files) within share 
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { PrimaryButton, Stack, TextField, DefaultButton, IStackTokens, Label } from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface RenameItemFormProps {
    isOpen: boolean;
    driveId: string;
    siteId: string;
    folderId: string;
    // itemId: string;
    // itemName:string;
    authToken: string;
    lang: string;
    userAgentApplication: Msal.UserAgentApplication;
    forceFileListRefresh: () => void;
}
export interface RenameItemFormState {
    renameTextBoxText?: string;
    showRenameItemModal: boolean;
    renameItemErrorMessage?: string;
    createButtonDisabled: boolean;
    itemId: string;
    itemName: string;
}

export class RenameItemForm extends React.Component<RenameItemFormProps, RenameItemFormState> {
    private _renameItemTitleId: string = getId('rename-item-title');
    private _renameItemSubtitleId: string = getId('rename-item-subtitle');

    constructor(props: RenameItemFormProps) {
        super(props);

        this.state = {
            showRenameItemModal: false,
            createButtonDisabled: true,
            itemId: "dummy",
            itemName: "dummy"
        }
    }

    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showRenameItemModal } = this.state;
        return (
            <Modal
                titleAriaId={this._renameItemTitleId}
                subtitleAriaId={this._renameItemSubtitleId}
                isOpen={showRenameItemModal}
                onDismiss={this._closeRenameItemModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._renameItemTitleId}><h6>{resource.str_HeaderChangeName}</h6></span>
                </div>
                <div id={this._renameItemSubtitleId} className="swpStyle-upload-file-modal-body">
                    <TextField theme={colorThemeLight} value={this.state.renameTextBoxText} label={resource.str_LblProvideNewName} errorMessage={this.state.renameItemErrorMessage} onChange={this._onRenameItemTextBoxChange.bind(this)}></TextField><br></br>
                    <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                        <PrimaryButton theme={colorThemeLight} onClick={this._onRenamItemClick.bind(this)} text={resource.str_ButtonChange} disabled={this.state.createButtonDisabled} />
                        <DefaultButton theme={colorThemeLight} onClick={this._closeRenameItemModal.bind(this)} text={resource.str_BtnCancel} />
                    </Stack>
                </div>
            </Modal>
        )
    }

    private _closeRenameItemModal() {
        config.DEBUG && console.log("_closeRenameItemModal");
        this.setState({ showRenameItemModal: false, createButtonDisabled: true, renameItemErrorMessage: "" });
    }

    public _openRenameModal(itemId: string, itemName: string) {
        config.DEBUG && console.log("_openRenameModal. Item name: " + itemName);
        this.setState({
            showRenameItemModal: true,
            itemId: itemId,
            itemName: itemName,
            renameTextBoxText: itemName
        });
    }

    private _onRenameItemTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        var pattern = new RegExp("^[0-9A-Za-z-()._ ółćążźęńśÓŁĆĄŻŹĘŃŚ\d]{0,50}$")
        if (event.target.value.toString().toLocaleLowerCase().match(pattern) != null) {
            config.DEBUG && console.log("OK");
            this.setState({
                renameTextBoxText: event.target.value,
                createButtonDisabled: true
            })
            if (event.target.value.length > 1) {
                this.setState({
                    createButtonDisabled: false,
                    renameItemErrorMessage: ""
                })
            }
        } else {
            config.DEBUG && console.log("Error");
            this.setState({
                renameItemErrorMessage: this.props.lang == "PL" ? gblResourcePL.str_ErrWrongName : gblResource.str_ErrWrongName,
                createButtonDisabled: true
            })
        }
    }

    private _onRenamItemClick() {
        config.DEBUG && console.log("_onRenamItemClick");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        graphService.updateItem(this.props.driveId, this.state.itemId, this.state.renameTextBoxText.trim()).then(result => {
            this.props.forceFileListRefresh();
        });


        this._closeRenameItemModal();

    }
}