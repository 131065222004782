//------------------------------------ Description ------------------------------------------------------------
//This form allows to enable or disable alerts on current site. Change is per user only.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import {Modal} from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { 
    PrimaryButton, 
    Stack, 
    DefaultButton, 
    IStackTokens,
    DayOfWeek,
    Text,
    Label
 } from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { Container, Row, Col } from "react-bootstrap";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from 'office-ui-fabric-react/lib/ProgressIndicator';

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress : { paddingTop: 0 }
  };

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface SetAlertFormProps { 
    isOpen:boolean ;
    authToken:string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    shareDetailsRefreshMethod: any;
    lang: string;
    currentUserUPN: string;
    userAlertState: number;
    refreshAfterAlertMethod: any;
    inviteId: number;
}
export interface SetAlertFormState {
    userTypeValue?:string;
    showSetAlertModal:boolean;
    SetAlertButtonDisabled: boolean;
    shareName: string;
    newShareClosureDate?: Date;
    contentLoad:boolean;
 }

export class SetAlertForm extends React.Component<SetAlertFormProps, SetAlertFormState> {
    private _SetAlertTitleId: string = getId('new-folder-title');
    private _SetAlertSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: SetAlertFormProps) {
        super(props);

        this.state = {
            showSetAlertModal:false,
            SetAlertButtonDisabled:true,
            shareName: "",
            contentLoad:false
        }
    }


    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showSetAlertModal, contentLoad, SetAlertButtonDisabled} = this.state;

        // function addDays(date: Date, days: number) {
        //   var result = new Date(date);
        //   result.setDate(result.getDate() + days);
        //   return result;
        // }

        // let today = new Date;

        return(
        <Modal
            titleAriaId={this._SetAlertTitleId}
            subtitleAriaId={this._SetAlertSubtitleId}
            isOpen={showSetAlertModal}
            onDismiss={this._closeSetAlertModal.bind(this)}
            isModeless={false}
            dragOptions={undefined}
            containerClassName="swpStyle-narrow-forms-modal-window"
            theme={colorThemeLight}
        >
            <div className="swpStyle-upload-file-modal-header">
                <span id={this._SetAlertTitleId}><h6>{resource.str_HeaderSetAlert}</h6></span>
            </div>
            <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles}/>
            <div id={this._SetAlertSubtitleId} className="swpStyle-upload-file-modal-body">
                {/* Form fields: */}
                <Container>
                    <Row>
                        {this.props.userAlertState == 1 ?
                            <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{resource.str_MsgSetAlertInfoOff}</p></Label>:
                            <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{resource.str_MsgSetAlertInfoOn}</p></Label>
                        }
                    </Row>
                </Container>  
                
                {/* Submit buttons: */}
                <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                    <PrimaryButton theme={colorThemeLight} onClick={this._onSetAlert.bind(this)} text={resource.str_BtnConfirm} />
                    <DefaultButton theme={colorThemeLight} onClick={this._closeSetAlertModal.bind(this)} text={resource.str_BtnCancel} />
                </Stack>
            </div>
        </Modal>
        )
    }

    private _closeSetAlertModal= (): void => {
        this.setState({ 
            showSetAlertModal: false,
        });
    }

    public _openSetAlertModal() {
        this.setState({ showSetAlertModal: true });
    }
  

    private _onSetAlert() {
        config.DEBUG && console.log("_onSetAlert");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        this.setState({contentLoad: true}) //start load
        if(this.props.userAlertState == 1){
            var setAlert = "0"
        } else if(this.props.userAlertState == 0){
            var setAlert = "1"
        } else {
            //in progress
        }

        graphService.SetAlertsToInProgress(this.props.inviteId).then(result => {
            graphService.SetAlert(this.props.currentShareData, this.props.currentUserUPN, setAlert, this.props.inviteId).then(result => {
                if(result){
                  //this.props.shareDetailsRefreshMethod(); //refreshing ShareDetails component
                  this.props.refreshAfterAlertMethod(); //performs refresh on invitation list to get correct alert settings for current user
                  this._closeSetAlertModal();
                }
            })
            this.setState({contentLoad: false}) //finish load     
        })

 
    }
    
}