//------------------------------------ Description ------------------------------------------------------------
//This form allows to generate request to create new share in SWP system. 
//Verfication is made for existing shares with the same title.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    TextField,
    DefaultButton,
    IStackTokens,
    Toggle,
    DayOfWeek,
    DatePicker,
    Dialog,
    DialogFooter,
    DialogType,
    Label
} from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { PLCalendarSettings, ENCalendarSettings } from "../components/Calendars";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from 'office-ui-fabric-react/lib/ProgressIndicator';

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: { paddingTop: 0 }
};

const dialogContentProps = {
    type: DialogType.normal,
    title: "Sukces",
    closeButtonAriaLabel: 'Zamknij',
    subText: gblResourcePL.str_LblCreateShareConfirm
};

const dialogErrorContentProps = {
    type: DialogType.normal,
    title: "Błąd zakładania obszaru",
    closeButtonAriaLabel: 'Zamknij',
    subText: gblResourcePL.str_ErrShareExist
};

const dialogModalProps = {
    isBlocking: true,
    styles: { main: { minWidth: 250, minHeight: 50 } }
};

export interface NewShareFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    lang: string;
    refreshAfterConfirmMethod: any;
}
export interface NewShareFormState {
    shareTitleTextBoxText?: string;
    companyTextBoxText?: string;
    shareTypeValue?: string;
    shareClosureDate?: Date;
    closureDateVisible: boolean;
    shareAIPValue?: boolean;
    showNewFolderModal: boolean;
    newShareErrorMessage?: string;
    newCompanyErrorMessage?: string;
    createButtonDisabled: boolean;
    requiredFieldCount: number;
    showConfirmDialog: boolean;
    showErrorDialog: boolean;
    contentLoad: boolean;
    titleTrue: number;
    companyTrue: number;
    dateTrue: number;
    lang: any;
}

export class NewShareForm extends React.Component<NewShareFormProps, NewShareFormState> {
    private _newFolderTitleId: string = getId('new-folder-title');
    private _newFolderSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: NewShareFormProps) {
        super(props);

        this.state = {
            showNewFolderModal: false,
            createButtonDisabled: true,
            requiredFieldCount: 0,
            closureDateVisible: false,
            showConfirmDialog: true,
            showErrorDialog: true,
            shareAIPValue: false,
            contentLoad: false,
            titleTrue: 0,
            companyTrue: 0,
            dateTrue: 0,
            lang: gblResourcePL
        }
    }



    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showNewFolderModal, showConfirmDialog, showErrorDialog, contentLoad, shareAIPValue, shareClosureDate, dateTrue, titleTrue, companyTrue } = this.state;
        let today = new Date;

        function addDays(date: Date, days: number) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        let tomorrow = addDays(today, 1);
        let maxDate = addDays(today, 90);
        return (
            <Modal
                titleAriaId={this._newFolderTitleId}
                subtitleAriaId={this._newFolderSubtitleId}
                isOpen={showNewFolderModal}
                onDismiss={this._closeNewShareModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-new-share-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._newFolderTitleId}><h6>{resource.str_HeaderCreateShare}</h6></span>
                </div>
                <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />
                <div id={this._newFolderSubtitleId} className="swpStyle-upload-file-modal-body">


                    <TextField required theme={colorThemeLight} placeholder={resource.str_PlhShareNameField} label={resource.str_InpProvideShareName} errorMessage={this.state.newShareErrorMessage} onChange={this._onNewShareTitleTextBoxChange.bind(this)} /><br></br>
                    <TextField required theme={colorThemeLight} placeholder={resource.str_PlhCompanyNameField} label={resource.str_InpProvideCompany} errorMessage={this.state.newCompanyErrorMessage} onChange={this._onNewCompanyNameTextBoxChange.bind(this)} /><br></br>
                    <DatePicker
                        minDate={tomorrow}
                        maxDate={maxDate}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={this.props.lang == "PL" ? PLCalendarSettings : ENCalendarSettings}
                        placeholder={resource.str_PhChooseDate}
                        ariaLabel={resource.str_InpClosureDate}
                        onSelectDate={this._onCalendarChange}
                        theme={colorThemeLight}
                        formatDate={this._onFormatDate}
                        label={resource.str_FieldClosureDateDisplay}
                        value={shareClosureDate}
                    />
                    <br></br>
                    <Toggle theme={colorThemeLight} label={resource.str_InpAIPfield} onText={resource.str_ToggleYes} offText={resource.str_ToggleNo} onChange={this._onAIPChange.bind(this)} checked={shareAIPValue} /><br></br>
                    {shareAIPValue && <Label theme={colorThemeLight}>{resource.str_WrnAIPShare}</Label>}<br></br>
                    <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                        <PrimaryButton theme={colorThemeLight} onClick={this._onCreateShareClick.bind(this)} text={resource.str_BtnConfirm} disabled={titleTrue + companyTrue + dateTrue < 3} />
                        <DefaultButton theme={colorThemeLight} onClick={this._closeNewShareModal.bind(this)} text={resource.str_BtnCancel} />
                    </Stack>

                    <Dialog
                        hidden={showConfirmDialog}
                        // title={this.state.lang.str_LblCreateShareConfirm} 
                        onDismiss={this._closeNewShareModal}
                        dialogContentProps={dialogContentProps}
                        modalProps={dialogModalProps}
                    >
                        <DialogFooter>
                            <PrimaryButton theme={colorThemeLight} onClick={this._closeNewShareModal} text={resource.str_BtnOK} />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={showErrorDialog}
                        onDismiss={this._closeErrorDialog}
                        dialogContentProps={dialogErrorContentProps}
                        modalProps={dialogModalProps}
                    >
                        <DialogFooter>
                            <PrimaryButton theme={colorThemeLight} onClick={this._closeErrorDialog} text={resource.str_BtnOK} />
                        </DialogFooter>
                    </Dialog>
                </div>
            </Modal>
        )
    }

    async componentDidMount() {
        this.setState({
            titleTrue: 0,
            companyTrue: 0,
            dateTrue: 0
        })
    }

    private _closeNewShareModal = (): void => {
        config.DEBUG && console.log("_closeNewFolderModal");
        this.setState({
            showNewFolderModal: false,
            showConfirmDialog: true,
            shareClosureDate: null,
            shareAIPValue: false,
            titleTrue: 0,
            companyTrue: 0,
            dateTrue: 0
        });
        config.DEBUG && console.log("Firing refresh moethod...");
        this.props.refreshAfterConfirmMethod()
    }

    public _openNewShareModal() {
        config.DEBUG && console.log("_openNewFolderModal");
        this.setState({ showNewFolderModal: true });
    }

    private _onCalendarChange = (date: Date | null | undefined): void => {
        this.setState({
            shareClosureDate: date,
            dateTrue: 1
        })
    }

    private _onAIPChange(ev: React.MouseEvent<HTMLElement>, checked: boolean) {
        config.DEBUG && console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
        if (checked) {
            this.setState({ shareAIPValue: true });
        } else {
            this.setState({ shareAIPValue: false });
        }
    }

    private _onNewShareTitleTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        var pattern = new RegExp("^[ 0-9A-Za-z-ółćążźęńśÓŁĆĄŻŹĘŃŚ_\d]{3,30}$");
        if (event.target.value.match(pattern) != null) {
            config.DEBUG && console.log(event.target.value);
            this.setState({
                //createButtonDisabled: false,
                shareTitleTextBoxText: event.target.value,
                newShareErrorMessage: "",
                titleTrue: 1
            })
        } else {
            config.DEBUG && console.log("Error");
            this.setState({
                newShareErrorMessage: this.state.lang.str_ErrWrongShareName,
                //createButtonDisabled: true
                titleTrue: 0
            })
        }

    }

    private _onNewCompanyNameTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        var pattern = new RegExp("^[ 0-9A-Za-z-ółćążźęńśÓŁĆĄŻŹĘŃŚ_\d]{3,30}$");
        if (event.target.value.toString().toLocaleLowerCase().match(pattern) != null) {
            this.setState({
                //createButtonDisabled: false,
                companyTextBoxText: event.target.value.trim(),
                newCompanyErrorMessage: "",
                companyTrue: 1
            })
        } else {
            config.DEBUG && console.log("Error");
            this.setState({
                newCompanyErrorMessage: this.state.lang.str_ErrWrongCompanyName,
                //createButtonDisabled: true,
                companyTrue: 0
            })
        }
        config.DEBUG && console.log(event.target.value);
    }

    private _onCreateShareClick() {
        config.DEBUG && console.log("_onCreateShareClick");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        this.setState({ contentLoad: true }) //start load
        // additional check on request list as well to avoid quickly created shares before they show up on shares list
        graphService.getShareRequestItemByTitle(this.state.shareTitleTextBoxText.trim()).then(requestResult => {
            config.DEBUG && console.log(requestResult.value)
            if (requestResult.value != undefined && requestResult.value.length > 0) { //something returned, not undefined
                if (requestResult.value[0].fields.Title.toLowerCase() == this.state.shareTitleTextBoxText.toLowerCase()) {
                    //show error dialog as such share already exist
                    config.DEBUG && console.log("Error. Share already exist")
                    this._showErrorDialog();
                    this.setState({ contentLoad: false }) //finish load
                }

                config.DEBUG && console.log("Space after title used")
                this._showErrorDialog();
                this.setState({ contentLoad: false }) //finish load

            } else {

                graphService.getShareItemByTitle(this.state.shareTitleTextBoxText).then(shareResult => {
                    config.DEBUG && console.log(shareResult.value)
                    if (shareResult.value != undefined && shareResult.value.length > 0) { //something returned
                        if (shareResult.value[0].fields.Title.toLowerCase() == this.state.shareTitleTextBoxText.toLowerCase()) {
                            //show error dialog as such share already been requested
                            config.DEBUG && console.log("Error. Share already exist on request list")
                            this._showErrorDialog();
                            this.setState({ contentLoad: false }) //finish load 
                        }
                    } else {
                        graphService.newShareRequest(this.state.shareTitleTextBoxText, this.state.companyTextBoxText, this.state.shareTypeValue, this.state.shareAIPValue, this.state.shareClosureDate).then(result => {
                            config.DEBUG && console.log(result.fields.Title);
                            config.DEBUG && console.log(this.state.shareTitleTextBoxText);
                            if (result.fields.Title == this.state.shareTitleTextBoxText) {
                                //this.setState({ showNewFolderModal: false });
                                this._showDialog();
                                this.setState({ contentLoad: false }) //finish load
                            }
                        })
                    }
                })
            }
            //stopping spinner in case conditions failed:
            this.setState({ contentLoad: false })
            this.setState({
                titleTrue: 0,
                companyTrue: 0,
                dateTrue: 0
            })
        })
    }

    private _onFormatDate = (date: Date): string => {
        if (this.props.lang == "PL") {
            return date.toLocaleDateString("pl-pl")
        } else {
            return date.toLocaleDateString("en-uk")
        }
    }

    private _showDialog = (): void => {
        this.setState({
            showConfirmDialog: false,
            shareClosureDate: null,
            shareAIPValue: false
        });
    }

    private _showErrorDialog = (): void => {
        this.setState({ showErrorDialog: false });
    }

    private _closeErrorDialog = (): void => {
        this.setState({ showErrorDialog: true });
    }
}