import * as React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavbarBrand from 'react-bootstrap/NavbarBrand';
import { TooltipHost } from "office-ui-fabric-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import {
    IconLookup,
    IconDefinition,
    findIconDefinition
} from '@fortawesome/fontawesome-svg-core'
import Dropdown from 'react-bootstrap/Dropdown';

const config = require("./config.json");

const logo = require("./images/Znak_NBP-wariant_1.png");
//const logo = require("./images/LogotypNBP.svg");

library.add(fas);
library.add(far);

//Manuals icon
const manualsLookup: IconLookup = { prefix: 'far', iconName: 'file-pdf' };
const manualsIconDefinition: IconDefinition = findIconDefinition(manualsLookup);

//help icon
const helpLookup: IconLookup = { prefix: 'far', iconName: 'question-circle' };
const helpIconDefinition: IconDefinition = findIconDefinition(helpLookup);

//user icon
const userLookup: IconLookup = { prefix: 'far', iconName: 'user' };
const userIconDefinition: IconDefinition = findIconDefinition(userLookup);

export interface HeaderProps {
    isAuthenticated: boolean,
    userName: any,
    userEmail: any,
    authButtonMethod: any,
    isGuest: string,
    lang: string
    onFileShareClick(shareName: string, shareGroupId: string, shareSiteId: string): void;
}
export interface HeaderState { isAuthenticated: boolean, userName: any, userEmail: any, authButtonMethod: any, isGuest: string }

//Fabric Icons: SignOut, DocumentSearch, MailAlert/MailTentative/Mail

export class Header extends React.Component<HeaderProps, HeaderState> {

    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        if (this.props.isAuthenticated) {
            return (
                <div>
                    <Navbar collapseOnSelect expand="lg" className="navbar-dark swpStyle-Header swpStyle-Header-Container">
                        <NavbarBrand className="side-panels">
                            <img src={logo} className="p-1 swpStyle-logo" />
                        </NavbarBrand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse>
                            <Nav className="ml-auto mr-auto text-swpStyle-headerText ">
                                <Nav.Item style={{ alignSelf: 'center' }}>
                                    {/* <h3>{resource.str_HeaderAppName}</h3> */}
                                </Nav.Item>
                            </Nav>
                            <Nav className="navbar side-panels">
                                <Nav.Item className="icon-set-flex">
                                    {/* <EmployeeLinks guestcheck={this.props.isGuest}/> */}
                                    <TooltipHost
                                        content={resource.str_TipSendHelpEmail}
                                        id="1"
                                        calloutProps={{ gapSpace: 0 }}
                                        styles={{ root: { display: 'inline-block' } }}
                                    >
                                        <Nav.Item className="icon-item">
                                            <Nav.Link href={resource.str_helpAccessLink}>
                                                <span className="swpStyle-header-icon">
                                                    <FontAwesomeIcon icon={helpIconDefinition} size="2x" />
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </TooltipHost>
                                    <TooltipHost
                                        content={resource.str_TipGoToManuals}
                                        id="2"
                                        calloutProps={{ gapSpace: 0 }}
                                        styles={{ root: { display: 'inline-block' } }}
                                    >
                                        <Nav.Item className="icon-item">
                                            <Nav.Link target="_blank" href="">
                                                <span className="swpStyle-header-icon">
                                                    <div onClick={
                                                        () => {
                                                            this.props.onFileShareClick(resource.str_ManualsShareName, "1", config.helpSiteId);
                                                        }
                                                    }>
                                                        <FontAwesomeIcon icon={manualsIconDefinition} size="2x" />
                                                    </div>
                                                </span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </TooltipHost>
                                    <TooltipHost
                                        content={resource.str_TipLogout}
                                        id="3"
                                        calloutProps={{ gapSpace: 0 }}
                                        styles={{ root: { margin: 'auto', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '8px' } }}
                                    >
                                        <Nav.Item className="icon-item">
                                            <div>
                                                <Dropdown alignRight>
                                                    <Dropdown.Toggle id="toggle" as={Nav} >
                                                        <span className="swpStyle-header-icon">
                                                            <FontAwesomeIcon className="swpStyle-header-icon" icon={userIconDefinition} size="2x" />
                                                        </span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu >
                                                        <Dropdown.Item ><h5>{this.props.userName}</h5></Dropdown.Item>
                                                        <Dropdown.Item >{this.props.userEmail}</Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={this.props.authButtonMethod}>{resource.str_BtnLogout}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Nav.Item>
                                    </TooltipHost>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <div className="swpStyle-AppName"><h3>{resource.str_HeaderAppName}</h3></div>
                </div>
            );
        } else {
            return (
                <div>
                    <Navbar expand="lg" className="navbar-dark swpStyle-Header swpStyle-Header-Container">
                        <NavbarBrand className="side-panels">
                            <img src={logo} className="p-1 swpStyle-logo" />
                        </NavbarBrand>
                        <Nav className="ml-auto mr-auto text-swpStyle-header">
                            <Nav.Item>
                                {/* <h4>{resource.str_HeaderAppName}</h4> */}
                            </Nav.Item>
                        </Nav>
                        <Nav className="side-panels"></Nav>
                    </Navbar>
                    <div className="swpStyle-AppName"><h3>{resource.str_HeaderAppName}</h3></div>
                </div>
            );
        }

    }
}