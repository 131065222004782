import * as React from 'react';
import { CommandButton } from 'office-ui-fabric-react/lib/Button';
import { colorThemeLight, colorThemeDark } from './Themes';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
// const resource = require("../LangFiles/resource.json");


export interface ShareListMenuProps {
    isOperator: boolean,
    // isOperatorAccessOnly: boolean,
    isAdmin: boolean,
    refreshMethod: any,
    openNewShareForm: any,
    lang: string
}


export class ShareListMenu extends React.Component<ShareListMenuProps> {
    constructor(props: ShareListMenuProps) {
        super(props);
        this.state = {
            showCallout: false
        };
    };

    public render(): JSX.Element {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***
        if(this.props.isAdmin){
            return (
                <div>
                    <CommandButton
                        role="menuitem"
                        title={resource.str_PopActions}
                        theme={colorThemeDark}
                        iconProps={{ iconName: 'CollapseMenu' }}
                        text={resource.str_BtnActions}
                        menuProps={{
                            shouldFocusOnMount: false,
                            shouldFocusOnContainer: true,
                            directionalHint: 6,
                            theme: colorThemeLight,
                            className: 'swpStyle-context-menu',
                            items: [
                                {
                                    key: 'admin',
                                    itemType: ContextualMenuItemType.Header,
                                    theme: colorThemeLight,
                                    text: 'Administracja'
                                },
                                {
                                    key: 'commsList',
                                    text: resource.str_BtnCommsList,
                                    theme: colorThemeLight,
                                    target: "_blank",
                                    href:"https://nbp365.sharepoint.com/sites/SWPPomoc/Lists/KomunikatyDlaUzytkownikowSWP/AllItems.aspx"
                                },
                                {
                                    key: 'manuals',
                                    text: resource.str_BtnManuals,
                                    theme: colorThemeLight,
                                    target: "_blank",
                                    href:"https://nbp365.sharepoint.com/sites/SWPPomoc/Shared%20Documents/Forms/AllItems.aspx"
                                },
                                {
                                    key: 'invites',
                                    text: resource.str_MenuItemExternalList,
                                    theme: colorThemeLight,
                                    target: "_blank",
                                    href:"https://nbp365.sharepoint.com/sites/SWP/Lists/ZaproszeniaDodatkowe/AllItems.aspx"
                                },
                                {
                                    key: 'shares',
                                    text: resource.str_MenuItemSharesList,
                                    theme: colorThemeLight,
                                    target: "_blank",
                                    href:"https://nbp365.sharepoint.com/sites/SWP/Lists/ObszaryWymianyPlikow/AllItems.aspx"
                                },
                                {
                                    key: 'raport',
                                    text: resource.str_MenuItemReport,
                                    theme: colorThemeLight,
                                    target: "_blank",
                                    href: "https://app.powerbi.com/groups/me/reports/e550f8c2-3f06-450b-833e-c28ddefcdce9/ReportSection"
                                },
                                {
                                    key: 'divider_1',
                                    itemType: ContextualMenuItemType.Divider
                                },
                                {
                                    key: 'newShare',
                                    text: resource.str_ButtonCreate,
                                    onClick: this.props.openNewShareForm,
                                    theme: colorThemeLight
                                },
                                {
                                    key: 'refresh',
                                    text: resource.str_BtnRefresh,
                                    onClick: this.props.refreshMethod,
                                    theme: colorThemeLight
                                }

                            ]
                        }}
                    />
                </div>
            );
        } else if(this.props.isOperator){
            return (
                <div>
                    <CommandButton
                        role="menuitem"
                        title={resource.str_PopActions}
                        theme={colorThemeDark}
                        iconProps={{ iconName: 'CollapseMenu' }}
                        text={resource.str_BtnActions}
                        menuProps={{
                            shouldFocusOnMount: false,
                            shouldFocusOnContainer: true,
                            directionalHint: 6,
                            theme: colorThemeLight,
                            className: 'swpStyle-context-menu',
                            items: [
                                {
                                    key: 'newShare',
                                    text: resource.str_ButtonCreate,
                                    onClick: this.props.openNewShareForm,
                                    theme: colorThemeLight
                                },
                                {
                                    key: 'divider_1',
                                    itemType: ContextualMenuItemType.Divider
                                },
                                {
                                    key: 'refresh',
                                    text: resource.str_BtnRefresh,
                                    onClick: this.props.refreshMethod,
                                    theme: colorThemeLight
                                }

                            ]
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <CommandButton
                        role="menuitem"
                        title={resource.str_PopActions}
                        theme={colorThemeDark}
                        iconProps={{ iconName: 'CollapseMenu' }}
                        text={resource.str_BtnActions}
                        menuProps={{
                            shouldFocusOnMount: false,
                            directionalHint: 6,
                            theme: colorThemeLight,
                            className: 'swpStyle-context-menu',
                            items: [
                                {
                                    key: 'refresh',
                                    text: resource.str_BtnRefresh,
                                    onClick: this.props.refreshMethod
                                }
                            ]
                        }}
                    />
                </div>
            );
        }

    
    }
}