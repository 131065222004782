import { loadTheme } from 'office-ui-fabric-react';

/* export const colorThemeLight = loadTheme({
  palette: {
    themePrimary: '#00695f',
    themeLighterAlt: '#eff9f8',
    themeLighter: '#c2e7e3',
    themeLight: '#93d2cc',
    themeTertiary: '#42a59b',
    themeSecondary: '#0f7b70',
    themeDarkAlt: '#005e55',
    themeDark: '#004f48',
    themeDarker: '#003b35',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#595858',
    neutralSecondary: '#373636',
    neutralPrimaryAlt: '#2f2e2e',
    neutralPrimary: '#000000',
    neutralDark: '#151515',
    black: '#0b0b0b',
    white: '#ffffff',
  }
}); */

export const colorThemeLight = loadTheme({ //New
  palette: {
    themePrimary: '#152e52',
    themeLighterAlt: '#f1f4f8',
    themeLighter: '#c8d4e3',
    themeLight: '#9eb1cb',
    themeTertiary: '#547097',
    themeSecondary: '#244066',
    themeDarkAlt: '#132a49',
    themeDark: '#10233e',
    themeDarker: '#0c1a2e',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    // neutralLight: '#edebe9',
    neutralLight: '#e5e8eb', //customized to fit NBP
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#c2c2c2',
    neutralSecondary: '#858585',
    neutralPrimaryAlt: '#4b4b4b',
    neutralPrimary: '#333333',
    neutralDark: '#272727',
    black: '#1d1d1d',
    white: '#ffffff'
  }});

/// Dark themes
export const colorThemeDark = loadTheme({ //New
  palette: {
    themePrimary: '#bdad7d',
    themeLighterAlt: '#080705',
    themeLighter: '#1e1c14',
    themeLight: '#393425',
    themeTertiary: '#71684b',
    themeSecondary: '#a6986e',
    themeDarkAlt: '#c3b488',
    themeDark: '#cdbf98',
    themeDarker: '#dad0b0',
    neutralLighterAlt: '#152d4f',
    neutralLighter: '#142c4e',
    neutralLight: '#132b4b',
    neutralQuaternaryAlt: '#122846',
    neutralQuaternary: '#112643',
    neutralTertiaryAlt: '#112440',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#152e52',
  }});

/* export const colorThemeDark = loadTheme({
  palette: {
    themePrimary: '#d7e6e6',
    themeLighterAlt: '#dae8e8',
    themeLighter: '#deebeb',
    themeLight: '#e3eeee',
    themeTertiary: '#e7f1f1',
    themeSecondary: '#ecf4f4',
    themeDarkAlt: '#f0f6f6',
    themeDark: '#f5f9f9',
    themeDarker: '#fafcfc',
    neutralLighterAlt: '#00665c',
    neutralLighter: '#00645a',
    neutralLight: '#006056',
    neutralQuaternaryAlt: '#005950',
    neutralQuaternary: '#00554d',
    neutralTertiaryAlt: '#00524a',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#00695f',
  }
}); */


