//------------------------------------ Description ------------------------------------------------------------
//This form allows users to rename share without requesting it from SharePoint Online Administartor's.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    DefaultButton,
    IStackTokens,
    TextField
} from 'office-ui-fabric-react';
import { Container } from "react-bootstrap";
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from 'office-ui-fabric-react/lib/ProgressIndicator';

const config = require("../components/config.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: { paddingTop: 0 }
};

export interface ManageDARequestFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    currectRequestData: any; //ShareSize is a property we are looking for
    directAccessRefreshMethod: any;
    lang: string;
}
export interface ManageDARequestFormState {
    showDirectAccessRequestModal: boolean;
    ApproveButtonDisabled: boolean;
    RejectButtonDisabled: boolean;
    shareName: string;
    operatorEmail: string;
    justification: string;
    contentLoad: boolean;
    comment?: string;
}

export class ManageDARequestForm extends React.Component<ManageDARequestFormProps, ManageDARequestFormState> {
    private _DirectAccessRequestTitleId: string = getId('new-folder-title');
    private _DirectAccessRequestSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: ManageDARequestFormProps) {
        super(props);

        this.state = {
            showDirectAccessRequestModal: false,
            ApproveButtonDisabled: false,
            RejectButtonDisabled: false,
            shareName: "",
            operatorEmail: "",
            justification: "",
            contentLoad: false,
            comment: ""
        }
    }

    render() {

        //Language section
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //end of language section

        const { showDirectAccessRequestModal, contentLoad, ApproveButtonDisabled, RejectButtonDisabled, operatorEmail, shareName, justification } = this.state;

        return (
            <Modal
                titleAriaId={this._DirectAccessRequestTitleId}
                subtitleAriaId={this._DirectAccessRequestSubtitleId}
                isOpen={showDirectAccessRequestModal}
                onDismiss={this._closeDirectAccessRequestModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._DirectAccessRequestTitleId}><h6>{resource.str_HeaderManageDirectAccess}</h6></span>
                </div>
                <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />

                <div id={this._DirectAccessRequestSubtitleId} className="swpStyle-upload-file-modal-body">
                    {/* Fields from DA request */}
                    <Container>
                        <TextField label={resource.str_LblShareName} readOnly defaultValue={shareName} />
                        <TextField label={resource.str_LblOperatorEmail} readOnly defaultValue={operatorEmail} />
                        <TextField label={resource.str_LblRequestDirectAccessJust} readOnly defaultValue={justification} multiline rows={3} />
                    </Container>
                    <br></br>
                    <Container>
                        <TextField label={resource.str_LblComment} multiline rows={3} placeholder={resource.str_plhComment} onChange={this._onCommentTextBoxChange.bind(this)} />
                    </Container>
                    <br></br>
                    {/* Submit buttons: */}
                    <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                        <PrimaryButton theme={colorThemeLight} onClick={() => this._DirectAccessRequest(resource.str_valueApproved)} text={resource.str_btnApprove} disabled={ApproveButtonDisabled} />
                        <PrimaryButton theme={colorThemeLight} onClick={() => this._DirectAccessRequest(resource.str_valueRejected)} text={resource.str_btnReject} disabled={RejectButtonDisabled} />

                        <DefaultButton theme={colorThemeLight} onClick={this._cancelDirectAccessRequest.bind(this)} text={resource.str_BtnCancel} />
                    </Stack>
                </div>
            </Modal>
        )
    }
    private _closeDirectAccessRequestModal = (): void => {
        this.setState({
            showDirectAccessRequestModal: false,
            ApproveButtonDisabled: false
        });
        this.props.directAccessRefreshMethod(); //refreshing ShareDetails component
    }

    private _cancelDirectAccessRequest = (): void => {
        this.setState({
            showDirectAccessRequestModal: false
        });
    }

    public _openDirectAccessRequestModal() {
        var statusNotNew = this.props.currectRequestData.status != "Nowy";
        this.setState({
            shareName: this.props.currectRequestData.shareName,
            operatorEmail: this.props.currectRequestData.email,
            justification: this.props.currectRequestData.justification,
            showDirectAccessRequestModal: true,
            ApproveButtonDisabled: statusNotNew,
            RejectButtonDisabled: statusNotNew
        });
    }

    private _DirectAccessRequest(status: string) { //to be fixed for new purpose

        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);

        graphService.setDirectAccessRequest(this.props.currectRequestData.requestItemID, status, this.state.comment).then(result => {
            config.DEBUG && console.log(result.value);
            this._closeDirectAccessRequestModal();

        }).catch(error => {
            config.DEBUG && console.log(error);
            this._cancelDirectAccessRequest();
        });

    }

    private _onCommentTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            comment: event.target.value
        })
    }

}