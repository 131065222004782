//------------------------------------ Description ------------------------------------------------------------
//This form allows users to change share size without requesting it from SharePoint Online Administartor's.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    DefaultButton,
    IStackTokens,
    TextField,
    Label,
    Dialog,
    DialogFooter,
    DialogType,
    Text
} from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from 'office-ui-fabric-react/lib/ProgressIndicator';

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress: { paddingTop: 0 }
};

const dialogContentProps = {
    type: DialogType.normal,
    // title: resource.str_LblCreateShareConfirm
};

const dialogModalProps = {
    isBlocking: true,
    styles: { main: { minWidth: 250, minHeight: 50 } }
};

export interface ExpandShareFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any; //ShareSize is a property we are looking for
    shareDetailsRefreshMethod: any;
    lang: string;
    isAdmin: boolean;
}
export interface ExpandShareFormState {
    userTypeValue?: string;
    showExpandShareModal: boolean;
    ExpandShareButtonDisabled: boolean;
    shareName: string;
    newShareSize?: number;
    showConfirmDialog: boolean;
    showErrorDialog: boolean;
    contentLoad: boolean;
    wrongSizeErrorMessage: string;
}

export class ExpandShareForm extends React.Component<ExpandShareFormProps, ExpandShareFormState> {
    private _ExpandShareTitleId: string = getId('new-folder-title');
    private _ExpandShareSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: ExpandShareFormProps) {
        super(props);

        this.state = {
            showExpandShareModal: false,
            ExpandShareButtonDisabled: true,
            shareName: "",
            showConfirmDialog: true,
            showErrorDialog: true,
            contentLoad: false,
            wrongSizeErrorMessage: ""
        }
    }


    render() {

        //Language section
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //end of language section

        const { showExpandShareModal, showConfirmDialog, contentLoad, wrongSizeErrorMessage, showErrorDialog } = this.state;
        let currentShareSize = this.props.currentShareData.WielkoscObszaru / 1024 //Regional String

        return (
            <Modal
                titleAriaId={this._ExpandShareTitleId}
                subtitleAriaId={this._ExpandShareSubtitleId}
                isOpen={showExpandShareModal}
                onDismiss={this._closeExpandShareModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._ExpandShareTitleId}><h6>{resource.str_HeaderExpandShare}</h6></span>
                </div>
                <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles} />

                <div id={this._ExpandShareSubtitleId} className="swpStyle-upload-file-modal-body">
                    <Label theme={colorThemeLight} style={{ fontWeight: 400, marginLeft: "auto", marginRight: "auto" }}><p>{resource.str_MsgCurrentShareSize + currentShareSize + "GB"}</p></Label>
                    <TextField theme={colorThemeLight} placeholder={this.props.isAdmin ? "Admin mode = Unlimited":resource.str_PHmsg } label={resource.str_LblEnterShareSizeValue} errorMessage={wrongSizeErrorMessage} onChange={this._onShareSizeChange.bind(this)}></TextField><br></br>
                    {/* Submit buttons: */}
                    <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                        <PrimaryButton theme={colorThemeLight} onClick={this._expandShare.bind(this)} text={resource.str_BtnConfirm} disabled={this.state.ExpandShareButtonDisabled} />
                        <DefaultButton theme={colorThemeLight} onClick={this._cancelExpandShareModal.bind(this)} text={resource.str_BtnCancel} />
                    </Stack>
                </div>
                <Dialog hidden={showConfirmDialog} onDismiss={this._closeExpandShareModal} dialogContentProps={dialogContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_LblCreateShareConfirm : gblResource.str_LblCreateShareConfirm}>
                    <DialogFooter>
                        <PrimaryButton theme={colorThemeLight} onClick={this._closeExpandShareModal} text={resource.str_BtnOK} />
                    </DialogFooter>
                </Dialog>
                <Dialog hidden={showErrorDialog} onDismiss={this._closeExpandShareModal} dialogContentProps={dialogContentProps} modalProps={dialogModalProps} title={this.props.lang == "PL" ? gblResourcePL.str_MsgErrorGenericTitle : gblResource.str_MsgErrorGenericTitle}>
                    <Text>{this.props.lang == "PL" ? gblResourcePL.str_MsgErrorGeneric : gblResource.str_MsgErrorGeneric}</Text>
                    <DialogFooter>
                        <PrimaryButton theme={colorThemeLight} onClick={this._closeErrorDialog} text={resource.str_BtnOK} />
                    </DialogFooter>
                </Dialog>
            </Modal>
        )
    }
    //value={this.props.currentShareData.ShareSize} 
    private _closeExpandShareModal = (): void => {
        this.setState({
            showExpandShareModal: false,
            showConfirmDialog: true,
            ExpandShareButtonDisabled: false
        });
        this.props.shareDetailsRefreshMethod(); //refreshing ShareDetails component
    }

    private _cancelExpandShareModal = (): void => { //NO refreshing ShareDetails component
        this.setState({
            showExpandShareModal: false,
            showConfirmDialog: true,
            ExpandShareButtonDisabled: false
        });
    }

    private _closeErrorDialog = (): void => {
        this.setState({
            showErrorDialog: true,
            showExpandShareModal: false
        });
        this.props.shareDetailsRefreshMethod();
    }

    public _openExpandShareModal() {
        this.setState({ showExpandShareModal: true });
    }

    private _onShareSizeChange(event: React.ChangeEvent<HTMLInputElement>) {
        var shareSize = parseInt(event.target.value)
        if (shareSize >= 1 && shareSize <= 100) {
            config.DEBUG && console.log(shareSize);
            this.setState({
                newShareSize: shareSize * 1024,
                ExpandShareButtonDisabled: false,
                wrongSizeErrorMessage: ""
            })
        } else if (shareSize == 0 || shareSize == undefined || shareSize == null || isNaN(shareSize)) {
            config.DEBUG && console.log(shareSize);
            this.setState({
                newShareSize: shareSize * 1024,
                ExpandShareButtonDisabled: true,
                wrongSizeErrorMessage: this.props.lang == "PL" ? gblResourcePL.str_ErrTooSmallOrIncorrect : gblResource.str_ErrTooSmallOrIncorrect
            })
        } else {
            config.DEBUG && console.log(shareSize);
            if (this.props.isAdmin) {
                this.setState({
                    newShareSize: shareSize * 1024,
                    ExpandShareButtonDisabled: false,
                    wrongSizeErrorMessage: ""
                })
            } else {
                this.setState({
                    ExpandShareButtonDisabled: true,
                    wrongSizeErrorMessage: this.props.lang == "PL" ? gblResourcePL.str_ErrTooBig : gblResource.str_ErrTooBig
                })
            }
        }

    }

    private _expandShare() {
        config.DEBUG && console.log("_expandShare");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        this.setState({ contentLoad: true, ExpandShareButtonDisabled: true }) //start load
        //first - set field SizeChange to true on shares list:
        graphService.changeShareSizeToInProgress(this.props.currentShareData.id).then(result => {
            if (result) {
                //second - add item to changesharesize list:
                config.DEBUG && console.log(this.props.currentShareData)
                graphService.changeShareSize(this.props.currentShareData, this.state.newShareSize).then(result => {
                    if (result) {
                        // 
                        this._showDialog();
                        this.setState({ contentLoad: false }) //finish load
                    } else {
                        //stop spinner when failed:
                        this.setState({ contentLoad: false }) //finish load
                        //show error message:
                        this._showErrorDialog();
                    }
                })
            } else {
                //stop spinner when failed:
                this.setState({ contentLoad: false }) //finish load
                //show error message:
                this._showErrorDialog();
            }
        })


    }

    private _showDialog = (): void => {
        this.setState({ showConfirmDialog: false, ExpandShareButtonDisabled: false });
    }

    private _showErrorDialog = (): void => {
        this.setState({ showErrorDialog: false, ExpandShareButtonDisabled: false });
    }

}