//------------------------------------ Description ------------------------------------------------------------
//This form allows to create new SharePoint Folder item
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import {Modal} from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { PrimaryButton, Stack, TextField, DefaultButton, IStackTokens } from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface NewFolderFormProps { 
    isOpen:boolean ;
    driveId: string;
    siteId: string;
    folderId: string;
    authToken:string;
    lang:string;
    userAgentApplication: Msal.UserAgentApplication;
    forceFileListRefresh:() => void;
}
export interface NewFolderFormState {
    newFolderTextBoxText?:string;
    showNewFolderModal:boolean;
    newFolderErrorMessage?: string;
    createButtonDisabled: boolean;
 }

export class NewFolderForm extends React.Component<NewFolderFormProps, NewFolderFormState> {
    private _newFolderTitleId: string = getId('new-folder-title');
    private _newFolderSubtitleId: string = getId('new-folder-subtitle');
    private langCode: string;

    constructor(props: NewFolderFormProps) {
        super(props);

        this.state = {
            showNewFolderModal:false,
            createButtonDisabled:true
        }
    }

    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showNewFolderModal } = this.state;
        return(
        <Modal
            titleAriaId={this._newFolderTitleId}
            subtitleAriaId={this._newFolderSubtitleId}
            isOpen={showNewFolderModal}
            onDismiss={this._closeNewFolderModal.bind(this)}
            isModeless={false}
            //containerClassName={styles.stickyContainer}
            dragOptions={undefined}
            containerClassName="swpStyle-new-folder-modal-window"
            theme={colorThemeLight}
        >
            <div className="swpStyle-upload-file-modal-header">
                <span id={this._newFolderTitleId}><h6>{resource.str_BtnNewFolder}</h6></span>
            </div>
            <div id={this._newFolderSubtitleId} className="swpStyle-upload-file-modal-body">
                <TextField theme={colorThemeLight} description={resource.str_PhNewFolderName} label={resource.str_LblNewFolderName} errorMessage={this.state.newFolderErrorMessage} onChange={this._onNewFolderTextBoxChange.bind(this)}/><br></br>
                <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                    <PrimaryButton theme={colorThemeLight} onClick={this._onCreateFolderClick.bind(this)} text={resource.str_ButtonCreateFolder} disabled={this.state.createButtonDisabled} />
                    <DefaultButton theme={colorThemeLight} onClick={this._closeNewFolderModal.bind(this)} text={resource.str_BtnCancel} />
                </Stack>
            </div>
        </Modal>
        )
    }

    private _closeNewFolderModal() {
        config.DEBUG && console.log("_closeNewFolderModal");
        this.setState({ showNewFolderModal: false, createButtonDisabled: true, newFolderErrorMessage: "" });
    }

    public _openNewFolderModal() {
        config.DEBUG && console.log("_openNewFolderModal");
        this.setState({ showNewFolderModal: true });
    }
    
    private _onNewFolderTextBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        var pattern = new RegExp("^[0-9A-Za-z-()._ ółćążźęńśÓŁĆĄŻŹĘŃŚ\d]{0,50}$")
        if (event.target.value.toString().toLocaleLowerCase().match(pattern)!=null && event.target.value) {
            config.DEBUG && console.log(event.target.value.toString().toLocaleLowerCase());
            this.setState({
                createButtonDisabled: false,
                newFolderTextBoxText: event.target.value,
                newFolderErrorMessage: ""
            })
            if(event.target.value.length > 1){
                this.setState({
                    createButtonDisabled: false,
                    newFolderErrorMessage: ""
                })
            } 
        } else {
            config.DEBUG && console.log("Error");
            this.setState({
                newFolderErrorMessage: this.props.lang == "PL"? gblResourcePL.str_ErrWrongFolderName: gblResource.str_ErrWrongFolderName,
                createButtonDisabled: true
            })
        }
    }

    private _onCreateFolderClick() {
        config.DEBUG && console.log("_onCreateFolderClick");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);

        if(this.props.folderId) {
            config.DEBUG && console.log("Creating folder in folder. Folder id: " +  this.props.folderId);
            graphService.createFolder(this.props.driveId, this.state.newFolderTextBoxText.trim(), this.props.folderId).then(result => {
                this.props.forceFileListRefresh();
            });
        } else {
            config.DEBUG && console.log("Creating folder in root. Folder id: " +  this.props.folderId);
            graphService.createFolder(this.props.driveId, this.state.newFolderTextBoxText.trim()).then(result => {
                this.props.forceFileListRefresh();
            });
        }

        this._closeNewFolderModal();
        
    }
}