import * as React from 'react';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { Async } from 'office-ui-fabric-react/lib/Utilities';

const config = require("./config.json");
const resource = require("../LangFiles/resource.json");

export interface ProgressBarState {
  percentComplete: number;
  percentLimit:number;
}

export interface ProgressBarProps {
  totalSize: number; //it's the summ of all files when multiple files uploaded
  fileSize: number;
  relativeStartPoint?: number //for multiple files
  fileName: string; //not neded for multiple files and folders
  hasFolderTree:boolean;
  startPosition?:number;
  chunkSize?:number;
}

const INTERVAL_DELAY = 700; //miliseconds - delay set only to stop bar from going to quickly between chunks
const INTERVAL_INCREMENT = 0.01; //length of each step - 0,01 = 1%

export class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {
  private _interval: number;
  private _async: Async;
  private barStartPoint: number;
  private barEndPoint: number;
  private lastSecondBlock: number;

  constructor(props: ProgressBarProps) {
    super(props);

    this._async = new Async(this);

    this.state = {
      percentComplete: 0,
      percentLimit: 1
    };
    this._startProgress = this._startProgress.bind(this);
  }

  public componentDidMount(): void {
    this._startProgress();
  }

  public componentWillUnmount(): void {
    this._async.dispose();
  }

  public render(): JSX.Element {
    const { percentComplete } = this.state;

    return <ProgressIndicator className={"swpStyle-progressbar"} label={this.props.fileName} description="" percentComplete={percentComplete} />;
  }

  private _startProgress(): void {


    //calculating start and end point
    if(this.props.fileSize == this.props.totalSize){ //for single file
      if(this.props.startPosition != 0){
        this.barStartPoint = this.props.startPosition / this.props.fileSize
        this.barEndPoint = (this.props.startPosition + this.props.chunkSize) / this.props.fileSize
      } else { //first chunk in file
        this.barStartPoint = 0
        this.barEndPoint = this.props.chunkSize / this.props.fileSize
      }
    } else { //for muliti file

      // config.DEBUG && console.log("#### Values passed from FileBrowser:...")
      // config.DEBUG && console.log("Total size: " + this.props.totalSize)
      // config.DEBUG && console.log("Single file size: " + this.props.fileSize)
      // config.DEBUG && console.log("Chunk size: " + this.props.chunkSize)
      // config.DEBUG && console.log("Start position for chunk: " + this.props.startPosition)
      // config.DEBUG && console.log("Relative position for file: " + this.props.relativeStartPoint)

      if(this.props.startPosition != 0){ //subsequent chunks in file...
        // config.DEBUG && console.log("$$$ Subsequent chunk")
        this.barStartPoint = (this.props.relativeStartPoint + this.props.startPosition) / this.props.totalSize
        config.DEBUG && console.log("Calculated starting point: " + this.barStartPoint)
        this.barEndPoint = (this.props.relativeStartPoint + this.props.startPosition + this.props.chunkSize) / this.props.totalSize
      } else { //first chunk in file
        // console.log("$$$ First chunk")
        if(this.props.relativeStartPoint != 0){
          this.barStartPoint = this.props.relativeStartPoint / this.props.totalSize
          config.DEBUG && console.log("Calculated starting point: " + this.barStartPoint)
        } else {
          this.barStartPoint = 0
          config.DEBUG && console.log("Calculated starting point: " + this.barStartPoint)
        }
        this.barEndPoint = this.props.chunkSize / this.props.totalSize
      }
    }


    this.setState({ 
      percentComplete: this.barStartPoint, //setting star value to next chunk size to 0 - start of the progress
      percentLimit: this.barEndPoint //setting limit for progress bar for current chunk
    });


    this._interval = this._async.setInterval(this.runOnStep, INTERVAL_DELAY); // updating progress based on calculated interval,depending on the file size
  }

  private runOnStep(){
    let percentComplete = this.state.percentComplete + INTERVAL_INCREMENT;

    if(percentComplete <= this.state.percentLimit){
      this.setState({
        percentComplete: percentComplete
      });
    }
  } 
}
