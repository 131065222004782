//------------------------------------ Description ------------------------------------------------------------
//This form allows Operators to generate full list of users having access to share.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import {
    PrimaryButton,
    Stack,
    DefaultButton,
    IStackTokens,
    Label,
    TextField
} from 'office-ui-fabric-react';
import { Container, Row, Col } from "react-bootstrap";
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");

const stackTokens: IStackTokens = { childrenGap: 10 };



export interface RequestDirectAccessFormProps {
    isOpen: boolean;
    authToken: string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    currentUserUPN: string;
    shareDetailsRefreshMethod: any;
    lang: string;
}
export interface RequestDirectAccessFormState {
    userTypeValue?: string;
    showRequestDirectAccessModal: boolean;
    RequestDirectAccessButtonDisabled: boolean;
    shareName: string;
    justification: string;
}

export class RequestDirectAccessForm extends React.Component<RequestDirectAccessFormProps, RequestDirectAccessFormState> {
    private _RequestDirectAccessListTitleId: string = getId('new-folder-title');
    private _RequestDirectAccessListSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: RequestDirectAccessFormProps) {
        super(props);

        this.state = {
            showRequestDirectAccessModal: false,
            RequestDirectAccessButtonDisabled: true,
            shareName: "",
            justification: ""
        }
    }


    render() {

        //*** Language section ***
        let resource: any;
        if (this.props.lang == "PL") {
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showRequestDirectAccessModal } = this.state;

        return (
            <Modal
                titleAriaId={this._RequestDirectAccessListTitleId}
                subtitleAriaId={this._RequestDirectAccessListSubtitleId}
                isOpen={showRequestDirectAccessModal}
                onDismiss={this._closeRequestDirectAccessModal.bind(this)}
                isModeless={false}
                dragOptions={undefined}
                containerClassName="swpStyle-narrow-forms-modal-window"
                theme={colorThemeLight}
            >
                <div className="swpStyle-upload-file-modal-header">
                    <span id={this._RequestDirectAccessListTitleId}><h6>{resource.str_HeaderRequestDirectAccess}</h6></span>
                </div>
                <div id={this._RequestDirectAccessListSubtitleId} className="swpStyle-upload-file-modal-body">
                    <Label theme={colorThemeLight} style={{ fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto" }}><p>{resource.str_MsgRequestDirectAccess}</p></Label>
                    <Container>
                        <Row>
                            <Col><TextField theme={colorThemeLight} label={resource.str_LblRequestDirectAccessJust} required multiline rows={3} onChange={this._onJustificationAdded.bind(this)}/><br></br></Col>
                        </Row>
                    </Container>
                    <div id="swpStyle-del-item-buttons">
                        <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                            <PrimaryButton theme={colorThemeLight} onClick={this._onRequestDirectAccess.bind(this)} text={resource.str_BtnConfirm} />
                            <DefaultButton theme={colorThemeLight} onClick={this._closeRequestDirectAccessModal.bind(this)} text={resource.str_BtnCancel} />
                        </Stack>
                    </div>
                </div>
            </Modal>
        )
    }

    private _closeRequestDirectAccessModal = (): void => {
        this.setState({
            showRequestDirectAccessModal: false,
        });
        //this.props.shareDetailsRefreshMethod();
    }

    public _openRequestDirectAccessModal() {
        this.setState({ showRequestDirectAccessModal: true });
    }

    private _onJustificationAdded(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value.length > 0) {
          this.setState({
            justification: event.target.value,
          })
        } else {
          this.setState({
            justification: ""
          })
        }
      }

    private _onRequestDirectAccess() {
        config.DEBUG && console.log("_onRequestDirectAccessType");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);

        graphService.requestDirectAccess(this.props.currentShareData, this.props.currentUserUPN, this.state.justification).then(result => {
            if (result) {
                this._closeRequestDirectAccessModal();
                
            }
        })

        this.props.shareDetailsRefreshMethod();

    }

}