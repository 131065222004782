import * as Msal from "msal";
import 'whatwg-fetch'
import { UserAgentApplication, AuthResponse } from "msal";

const config = require("./config.json");
const resource = require("../LangFiles/resource.json");

class ChunkUploadResult {
  status: number;
  nextExpectedRange: string;
  fileId: string;
}

export class GraphService {
  private _authToken: string;
  private userAgentApplication: UserAgentApplication;
  private msalConfig: Msal.Configuration;
  private account: Msal.Account;
  private msalAuthParams: Msal.AuthenticationParameters;
  private loginRequest: Msal.AuthenticationParameters;
  private tokenRequest: any;

  constructor(_authToken: string, userAgentApplication: Msal.UserAgentApplication) {
    this._authToken = _authToken;
    this.msalConfig = {
      auth: {
        clientId: config.appId,
        authority: config.authority
      },
      cache: {
        // cacheLocation: "sessionStorage",
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
      }
    }

    this.msalAuthParams = {
      scopes: config.scopes
    }

    if (!this.userAgentApplication) {
      this.userAgentApplication = new Msal.UserAgentApplication(this.msalConfig);
    }

    this.loginRequest = {
      scopes: config.scopes
    }

    this.tokenRequest = {
      scopes: config.scopes,
      authority: config.authority,
      forceRefresh: false
    }

  }

  //#region - General

  async getNewsItems(): Promise<any> {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var news = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.helpSiteId + "/lists/" + config.newsListId + "/items?expand=fields&filter=fields/Status eq '" + resource.str_FieldMessageActive + "'&$orderby=fields/DataPublikacji desc";

    config.DEBUG && console.log("Calling: " + graphEndpoint);
    try {
      const response = await fetch(graphEndpoint, options);
      const jsonResponse = response.json();
      config.DEBUG && console.log("Response received");
      //config.DEBUG && console.log(jsonResponse);
      news = jsonResponse;
      return news;
    } catch {
      return null;
    }
  }

  //#endregion - General

  //#region - Auth
  // async GetToken() { //OLD
  //   this.userAgentApplication.acquireTokenSilent(this.tokenRequest).then(response => {
  //     this._authToken = response.accessToken;
  //     //console.log(response)
  //   }).catch(error => {
  //     if (error.errorMessage.indexOf("interaction_required") !== -1) {
  //       this.userAgentApplication.acquireTokenPopup(this.tokenRequest).then(response => {
  //         this._authToken = response.accessToken;
  //       }).catch(error => {
  //         this.userAgentApplication.acquireTokenSilent(this.tokenRequest).then(response => {
  //           this._authToken = response.accessToken;
  //         });
  //       });
  //     };
  //   })
  // }

  async GetToken() {
    try {
      const response: AuthResponse = await this.userAgentApplication.acquireTokenSilent(this.tokenRequest);
      this._authToken = response.accessToken;
    } catch (error) {
      if (error.errorMessage && error.errorMessage.indexOf("interaction_required") !== -1) {
        try {
          const response: AuthResponse = await this.userAgentApplication.acquireTokenPopup(this.tokenRequest);
          this._authToken = response.accessToken;
        } catch (popupError) {
          console.error(popupError);
        }
      } else {
        console.error(error);
      }
    }
  }

  async getUserDetails(userId: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var user = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/users/" + userId;
    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    user = jsonResponse;
    return user;
  }

  //#endregion - Auth

  //#region - Groups
  async getUserGroups() {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    //config.DEBUG && console.log("Getting shares by token " + this._authToken);

    var groups = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/me/memberOf?$select=id,displayName,mailNickname,proxyAddresses,description";

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    groups = jsonResponse;
    config.DEBUG && console.log(groups)
    return groups;

  }

  async getGroupsForAdmin() {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    //config.DEBUG && console.log("Getting shares by token " + this._authToken);

    var adminGroups = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/groups?$filter=startswith(mail,'" + config.owpGroupPrefix + "')&$top=500";

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    adminGroups = jsonResponse;
    config.DEBUG && console.log(adminGroups)
    return adminGroups;

  }

  async GetCurrentShareGroupOwners(groupId: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    config.DEBUG && console.log("Getting ShareGroup owners. Group ID: " + groupId);

    var owners = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/groups/" + groupId + "/owners";

    config.DEBUG && console.log("Calling endpoint: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const jsonResponse = await response.json();
      owners = jsonResponse;

      config.DEBUG && console.log(owners);

      return owners;
    } else {
      return null;
    }
  }

  // async GetSWPAdministrators() { //unused
  //   var headers = new Headers();
  //   await this.GetToken();
  //   var bearer = "Bearer " + this._authToken;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "GET",
  //     headers: headers
  //   };

  //   config.DEBUG && console.log("Getting SWP admins. Admins group ID: " + config.swpAdminsGroupId);

  //   var admins = null;

  //   var graphEndpoint = "https://graph.microsoft.com/v1.0/groups/" + config.swpAdminsGroupId + "/members";
  //   //correct endpoint: // var graphEndpoint = "https://graph.microsoft.com/v1.0/me/memberOf?$filter=id eq " + config.swpAdminsGroupId

  //   config.DEBUG && console.log("Calling endpoint: " + graphEndpoint);

  //   const response = await fetch(graphEndpoint, options);
  //   if (response.ok) {
  //     const jsonResponse = await response.json();
  //     admins = jsonResponse;

  //     config.DEBUG && console.log(admins);

  //     return admins;
  //   } else {
  //     return null;
  //   }
  // }

  //#endregion - Groups

  //#region - Files

  async getShareFiles(shareId: string, driveId?: string, folderId?: string): Promise<any> {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Pragma", "no-cache");
    headers.append("Cache-Control", "no-store, no-cache, must-revalidate");
    var options = {
      method: "GET",
      headers: headers
    };

    var files = null;
    var apiPath = "";

    if (folderId && driveId) {
      apiPath = "/sites/" + shareId + "/drives/" + driveId + "/items/" + folderId + "/children";
    } else {
      apiPath = "/sites/" + shareId + "/drive/root/children";
    }

    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath + "?select=webDavUrl,weburl,@microsoft.graph.downloadUrl,name,lastmodifiedby,lastModifiedDateTime,size,id,folder";

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    files = jsonResponse;
    config.DEBUG && console.log(files);
    return files;
  }

  // async getFileToDwonload(groupId: string, itemId: string): Promise<any> {
  //   var headers = new Headers();
  //   await this.GetToken();
  //   var bearer = "Bearer " + this._authToken;
  //   headers.append("Authorization", bearer);
  //   headers.append("Access-Control-Allow-Origin", "*");
  //   var options = {
  //     method: "GET",
  //     headers: headers
  //   };

  //   var file = null;
  //   var apiPath = "";

  //   apiPath = "/groups/" + groupId + "/drive/items/" + itemId + "/content"

  //   var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;

  //   const response = await fetch(graphEndpoint, options);
  //   //const jsonResponse = response.json();
  //   const responseHeaders = response.headers;
  //   //file = jsonResponse;
  //   //config.DEBUG && console.log(file);
  //   return responseHeaders;
  // }

  async getSiteId(groupId: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var siteId = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/groups/" + groupId + "/sites/root?$select=id";

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    siteId = jsonResponse;
    return siteId;
  }

  async getDriveId(siteId: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var driveIds = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + siteId + "/drives?$select=id";

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    driveIds = jsonResponse;


    return jsonResponse;
  }

  async createFolder(driveId: string, folderName: string, folderId?: string) {
    config.DEBUG && console.log("Create folder, folder id:" + folderId)
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    config.DEBUG && console.log("===== Creating Folder for driveid: " + driveId)

    const driveItem = {
      name: folderName,
      folder: {},
      "@microsoft.graph.conflictBehavior": "replace"
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(driveItem)
    };

    var apiPath = "";
    var newFolder = null;


    if (folderId) {
      apiPath = "/drives/" + driveId + "/items/" + folderId + "/children";
      config.DEBUG && console.log("GraphService creating folder in subfolder. Folder id: " + folderId + " API path: " + apiPath);
    } else {
      apiPath = "/drives/" + driveId + "/items/root/children";
      config.DEBUG && console.log("GraphService creating folder in root");
    }

    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;

    config.DEBUG && console.log(JSON.stringify(driveItem));
    config.DEBUG && console.log(graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    newFolder = jsonResponse;

    return newFolder;
  }

  async getFolder(folderPath: string, groupId?: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);

    var options = {
      method: "GET",
      headers: headers,
    };

    var apiPath = "";
    var folder = null;

    apiPath = "/groups/" + groupId + "/drive/root:/" + folderPath;
    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;

    // config.DEBUG && console.log(graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    folder = jsonResponse;

    return folder;
  }

  async getFolderChildItems(itemId: string, groupId?: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);

    var options = {
      method: "GET",
      headers: headers,
    };

    var apiPath = "";
    var childItems = null;

    apiPath = "/groups/" + groupId + "/drive/items/" + itemId + "/children";
    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;

    // config.DEBUG && console.log(graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    childItems = jsonResponse;

    return childItems;
  }

  async deleteItems(driveId: string, itemIds: string[]) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "DELETE",
      headers: headers
    };

    var apiPath = "";

    for (let i: number = 0; i < itemIds.length; i++) {
      apiPath = "/drives/" + driveId + "/items/" + itemIds[i];

      var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;
      config.DEBUG && console.log(graphEndpoint);

      await fetch(graphEndpoint, options);
    }
  }

  async uploadFile(file: File, siteId: string, driveId: string, folder?: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "PUT",
      headers: headers,
      body: file
    };

    //config.DEBUG && console.log("Upload file session:  " + file.name);

    let apiPath: string;
    if (folder) {
      apiPath = "/sites/" + siteId + "/drives/" + driveId + "/root:/" + folder + "/" + file.name + ":/content";
    } else {
      apiPath = "/sites/" + siteId + "/drives/" + driveId + "/root:/" + file.name + ":/content";
    }

    var fileRef = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;

    config.DEBUG && console.log("Upload to api path: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    fileRef = jsonResponse;

    return fileRef;
  }

  async uploadLargeFile(file: File, siteId: string, driveId: string, folder?: string, returnChunkMethod?: any) {
    config.DEBUG && console.log("uploadLargeFile");
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var apiPath = "";

    var requestBody = {
      "item": {
        "@microsoft.graph.conflictBehavior": "replace"
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody)
    };

    if (folder) {
      apiPath = "/sites/" + siteId + "/drives/" + driveId + "/root:/" + folder + "/" + file.name + ":/createUploadSession";
    } else {
      apiPath = "/sites/" + siteId + "/drives/" + driveId + "/root:/" + file.name + ":/createUploadSession";
    }

    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;
    config.DEBUG && console.log("Create upload session: " + graphEndpoint);
    var jsonResponse: any
    var response: any

    response = await fetch(graphEndpoint, options);
    if (response.ok) {
      jsonResponse = await response.json();
    } else {
      //try again after a 1 second and recollect token
      await this.GetToken();
      setTimeout(async result => {
        response = await fetch(graphEndpoint, options);
        jsonResponse = await response.json();
      }, 1000);
    }


    config.DEBUG && console.log("Upload URL: " + jsonResponse.uploadUrl);
    var url = jsonResponse.uploadUrl;
    const resp = await this.uploadChunks(file, url, returnChunkMethod);
    config.DEBUG && console.log(resp)
    return resp;
  }

  async uploadChunks(file: File, uploadUrl: string, returnChunkMethod?: any) {
    config.DEBUG && console.log("uploading chunks");
    var reader = new FileReader();
    let chunkUploadResult: ChunkUploadResult;

    var position = 0;
    //creating variable chunk length - based on file size

    config.DEBUG && console.log("File size is: " + file.size);

    if (file.size < 4194304) { //less than 4MB
      var chunkLength = 327680 //320KB
    } else if (file.size >= 4194304 && file.size <= 32768000) { //between 4MB and 16MB
      var chunkLength = 3276800 //3MB
    } else {
      var chunkLength = 32768000 //32MB
    }

    var continueRead = true;

    while (continueRead) {
      //GET FILE CHUNK
      let stopByte = position + chunkLength;
      config.DEBUG && console.log("Sending req to read in chunk bytes from " + position + " to " + stopByte);

      var chunk = await this.readFragmentAsync(file, position, stopByte) as ArrayBuffer;

      returnChunkMethod(position, chunk.byteLength, file.size) //passing back data to FileBrowser component

      if (chunk.byteLength > 0) {
        continueRead = true;
      } else {
        break;
      }
      config.DEBUG && console.log("Chunk bytes received: " + chunk.byteLength);

      //UPLOAD CHUNK
      config.DEBUG && console.log("Uploading chunk");

      chunkUploadResult = await this.UploadChunk(chunk, uploadUrl, position, file.size) as ChunkUploadResult;
      if (chunkUploadResult.status === 201 || chunkUploadResult.status === 200) {
        config.DEBUG && console.log("Reached last chunk. Code: " + chunkUploadResult.status);
        continueRead = false;
      } else {
        config.DEBUG && console.log("Continuing. Code: " + chunkUploadResult.status);
        position = Number(chunkUploadResult.nextExpectedRange.split("-")[0]);
      }
    }
    return chunkUploadResult;
  }

  async UploadChunk(chunk: ArrayBuffer, uploadUrl: string, position: number, totalLength: number) {
    var max = position + chunk.byteLength - 1;
    var headers = new Headers();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/octet-stream");
    var crHeader = "bytes " + position + "-" + max + "/" + totalLength;
    headers.append("Content-range", crHeader)

    var options = {
      method: "PUT",
      headers: headers,
      body: chunk
    };

    config.DEBUG && console.log("Content-Range header being set is : " + crHeader);
    config.DEBUG && console.log("Chunk size is: " + chunk.byteLength + " bytes");

    //var uploadResult = response.json();

    return new Promise<ChunkUploadResult>((resolve, reject) => {
      config.DEBUG && console.log("uploadUrl: " + uploadUrl);
      let chunkUploadResult: ChunkUploadResult = new ChunkUploadResult();

      const response = fetch(uploadUrl, options).then(
        result => {
          config.DEBUG && console.log("Request status is: " + result.status);
          chunkUploadResult.status = result.status;
          result.json().then(jsonResult => {
            config.DEBUG && console.log(response);
            config.DEBUG && console.log("Request status is: " + result.status);
            if (jsonResult.nextExpectedRanges) {
              config.DEBUG && console.log("Next expected range is: " + jsonResult.nextExpectedRanges[0]);

            }
            chunkUploadResult.status = result.status;
            if (jsonResult.nextExpectedRanges) {
              chunkUploadResult.nextExpectedRange = jsonResult.nextExpectedRanges[0];
            }
            if (result.status === 200 || result.status === 201) {
              chunkUploadResult.fileId = jsonResult.id;
            }
            resolve(chunkUploadResult);
          });
        }
      )
    });



  }

  async readFragmentAsync(file: File, startByte: number, stopByte: number) {
    //let frag:ArrayBuffer = null;
    var frag = null;
    const reader = new FileReader();

    config.DEBUG && console.log("readFragmentAsync start byte: " + startByte + " stop byte: " + stopByte);
    var blob = file.slice(startByte, stopByte);

    reader.readAsArrayBuffer(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = (event) => {
        config.DEBUG && console.log("OnLoaded Called " + reader.result);
        if (reader.readyState === reader.DONE) {
          frag = reader.result;
          resolve(frag);
        }
      };
    });
  }

  async updateItem(driveId: string, itemId: string, fileName: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var apiPath = "/drives/" + driveId + "/items/" + itemId;
    var graphEndpoint = "https://graph.microsoft.com/v1.0" + apiPath;
    var updatedItem = null;

    config.DEBUG && console.log("Upload to api path: " + graphEndpoint);

    const updateBody = {
      name: fileName
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(updateBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    updatedItem = jsonResponse;
    return updatedItem;
  }

  //#endregion - Files

  //#region - Roles

  async getOperatorsFromInvitations(groupId: string, userUPN: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    // var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldUserTypeStatic + " eq 'Operator' and fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldUserUPN + " eq '" + userUPN.replace(/#/g, "%23") + "'";
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/Operator eq 1 and fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldUserUPN + " eq '" + userUPN.replace(/#/g, "%23") + "'";

    config.DEBUG && console.log("Getting operators invitations: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const operatorSites = await response.json();
      config.DEBUG && console.log(operatorSites);
      return operatorSites;
    } else {
      return null;
    }
  }

  async getInternalUsersFromInvitations(groupId: string, userUPN: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldUserUPN + " eq '" + userUPN.replace(/#/g, "%23") + "'";

    config.DEBUG && console.log("Getting operators invitations: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const operatorSites = await response.json();
      config.DEBUG && console.log(operatorSites);
      return operatorSites;
    } else {
      return null;
    }
    //https://graph.microsoft.com/v1.0/sites/marcinlabdemo.sharepoint.com,8e41775d-56a0-4b18-8a8d-363c60e95a6f,21158785-0d63-47f9-a5a0-ca9f1d38176e/lists/575c6eac-0735-4151-8755-f15cfd148df7/items?expand=fields&filter=fields/UserType eq 'Operator'
  }

  async getOperatorsFromShareList(groupId: string, userUPN: string) {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items?expand=fields&filter=fields/" + config.sharesListGroupIdFieldName + " eq '" + groupId.trim() + "' and fields/" + config.sharesListOperatorEmailFieldName.trim() + " eq '" + userUPN.replace(/#/g, "%23") + "'";

    config.DEBUG && console.log("Getting operators from share list: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const operatorSites = await response.json();
      config.DEBUG && console.log(operatorSites);
      return operatorSites;
    } else {
      return null;
    }
  }

  async getAllShareListItems() {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items?$top=1000&$expand=fields";

    //pulling all list items from shares list (top 500)

    config.DEBUG && console.log("Getting share list items: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);


    if (response.ok) {
      const sharesItems = await response.json();
      //console.log(sharesItems["@odata.nextLink"])
      config.DEBUG && console.log(sharesItems);
      return sharesItems;
    } else {
      return null;
    }
  }

  async getAllUsersFromInvitations() {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?$top=4000&$expand=fields";

    config.DEBUG && console.log("Getting all invitations: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const operatorSites = await response.json();
      config.DEBUG && console.log(operatorSites);
      return operatorSites;
    } else {
      return null;
    }
  }

  async checkIfSWPAdmin() {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    config.DEBUG && console.log("Getting SWP admins. Admins group ID: " + config.swpAdminsGroupId);

    var admin = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/me/memberOf?$filter=id eq '" + config.swpAdminsGroupId + "'"

    config.DEBUG && console.log("Calling endpoint: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    if (response.ok) {
      const jsonResponse = await response.json();
      admin = jsonResponse;

      config.DEBUG && console.log(admin);

      return admin;
    } else {
      return null;
    }
  }

  async checkIfSWPOperator() {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    config.DEBUG && console.log("Getting SWP admins. Admins group ID: " + config.operatorsGroupId);

    var operator = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/me/memberOf?$filter=id eq '" + config.operatorsGroupId + "'"

    config.DEBUG && console.log("Calling endpoint: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    if (response.ok) {
      const jsonResponse = await response.json();
      operator = jsonResponse;

      config.DEBUG && console.log(operator);

      return operator;
    } else {
      return null;
    }
  }

  // async checkIfSWPOperatorAccessOnly() { //not used
  //   var headers = new Headers();
  //   await this.GetToken();
  //   var bearer = "Bearer " + this._authToken;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "GET",
  //     headers: headers
  //   };

  //   config.DEBUG && console.log("Getting SWP admins. Admins group ID: " + config.operatorsAccessOnlyGroupId);

  //   var operator = null;

  //   var graphEndpoint = "https://graph.microsoft.com/v1.0/me/memberOf?$filter=id eq '" + config.operatorsAccessOnlyGroupId + "'"

  //   config.DEBUG && console.log("Calling endpoint: " + graphEndpoint);

  //   const response = await fetch(graphEndpoint, options);
  //   if (response.ok) {
  //     const jsonResponse = await response.json();
  //     operator = jsonResponse;

  //     config.DEBUG && console.log(operator);

  //     return operator;
  //   } else {
  //     return null;
  //   }
  // }

  //#endregion - Roles

  //#region - Share Item operation functions

  async newShareRequest(title: string, company: string, shareType: string, isAIP: boolean, closureDate: Date): Promise<any> {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareRequests + "/items";
    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": title,
        "Firma": company,
        // "RodzajObszaru": shareType,
        "RodzajObszaru": "Terminowy",
        "DodatkoweZabezpieczenia": isAIP,
        "DataDezaktywacjiObszaru": closureDate
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    config.DEBUG && console.log(jsonResponse);
    return newItem;
  }

  async newUserRequest(
    firstName: string,
    lastName: string,
    userType: string,
    email: string,
    phoneNr: string,
    shareId: string,
    accountName: any,
    groupId: string,
    changeOwner: string,
    isAdded: string,
    isOperator: any
    ): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items";

    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": "Zaproszenie dla użytkownika",
        "Imie": firstName,
        "Nazwisko": lastName,
        "Rodzaj": userType,
        "Email": email,
        "ShareNameLookupId": shareId,
        "NazwaUPN": accountName,
        "Status": resource.str_FieldValueInProgress,
        "StatisGroupId": groupId,
        "Dodawany": isAdded,
        "ZmianaWlasciciela": changeOwner,
        "NumerTelefonu": phoneNr,
        "Operator": isOperator,
        "DedykowaneKonto": false
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    config.DEBUG && console.log(response)
    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async resetCredForExternalUser(
    upn: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNr: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpResetCredentialsList + "/items";

    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": "Reset poświadczeń",
        "UPN": upn,
        "Imie": firstName,
        "Nazwisko": lastName,
        "Email": email,
        "Status": resource.str_FieldValueInProgress,
        "Telefon": phoneNr
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    config.DEBUG && console.log(response)
    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async changeShareStatus(shareStatus: string, shareID: string, delayed: boolean, delayDate: Date): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareStatusChangeList + "/items";
    var newItem = null;

    var action = resource.str_FieldActionValue + shareStatus;

    var newDelayedDateString;
    if (delayDate) {
      config.DEBUG && console.log(delayDate.toUTCString());
      config.DEBUG && console.log(delayDate.toDateString());
      newDelayedDateString = delayDate.toDateString()
    } else {
      newDelayedDateString = null
    }

    const newBody = {
      "fields": {
        "Title": "Zmiana stanu obszaru",
        "Status": "Nowe",
        "Akcja": action,
        "ObszarLookupId": shareID,
        "NowyRodzajObszaru": "Terminowy",
        "NowyStatusObszaru": shareStatus,
        "Opoznienie": delayed,
        "OpoznionaDataDezaktywacji": newDelayedDateString
      }
    }

    //sharesListId

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async continueShareRequest(shareRequestData: any): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareRequests + "/items/" + shareRequestData.shareItemId + "/fields";
    var changedItem = null;

    config.DEBUG && console.log("Create new item in changeShareStatusList: " + graphEndpoint);

    const newBody = {
      "StatusZadania": "Etykieta gotowa"
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    changedItem = jsonResponse;
    return changedItem;
  }

  async cleanShareRequest(shareRequestData: any): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareRequests + "/items/" + shareRequestData.shareItemId + "/fields";
    var changedItem = null;

    config.DEBUG && console.log("Create new item in changeShareStatusList: " + graphEndpoint);

    const newBody = {
      "StatusZadania": "DoUsuniecia"
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    changedItem = jsonResponse;
    return changedItem;
  }

  async changeShareStatusOnGoing(shareStatus: string, shareID: string, delayDate: Date): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareID + "/fields";
    var modifiedItem = null;

    config.DEBUG && console.log("Changing status of share item using: " + graphEndpoint);
    var newString;
    if (shareStatus == null) {
      newString = resource.str_FieldChangeInProgress
    } else {
      newString = shareStatus
    }

    var newDelayedDateString;
    if (delayDate) {
      newDelayedDateString = delayDate.toDateString()
    } else {
      newDelayedDateString = null
    }

    var newBody = {
      "StatusObszaru": newString,
      "OpoznionaDataDezaktywacji": newDelayedDateString
    }


    //sharesListId

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async changeShareSizeToInProgress(shareID: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareID + "/fields";
    var modifiedItem = null;

    config.DEBUG && console.log("Changing status of share item using: " + graphEndpoint);
    var newBody; //new  share status variable from button
    newBody = {
      "ZmianaRozmiaru": true
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async changeShareSize(shareData: any, newSize: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    //graphEndpoint needs to be changed before full deployment
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpChangeSizeList + "/items";
    var modifiedItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": "Change size",
        "NowyRozmiar": newSize,
        "IdentyfikatorGrupy": shareData.IdentyfikatorGrupy, //Regional string
        "Status": "New",
        "IdElementuObszaru": shareData.id,
        "URL": shareData.AdresObszaru //Regional string
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async renameShareInProgress(shareID: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareID + "/fields";
    var modifiedItem = null;

    config.DEBUG && console.log("Changing status of share Title using: " + graphEndpoint);
    var newBody; //new  share status variable from button
    newBody = {
      "ZmianaNazwy": true
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async renameShare(shareData: any, newName: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    //graphEndpoint needs to be changed before full deployment
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpRenameShareList + "/items";
    var modifiedItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": "Change Title",
        "NowaNazwa": newName,
        "IdentyfikatorGrupy": shareData.IdentyfikatorGrupy, //Regional string
        "IdElementuObszaru": shareData.id,
        "URL": shareData.AdresObszaru //Regional string
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async changeUserStatusOnGoing(userItemId: number, cleanValue: boolean): Promise<any> { //method used when upgrading/downgrading role

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    config.DEBUG && console.log("Current user ID: " + userItemId);
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items/" + userItemId + "/fields";

    //itemId
    var modifiedItem = null;

    const newBody = {
      "ZmianaRoli": "tak",
      "Operator": cleanValue
    }
    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async changeRole(userItemData: any, newStatus: boolean, changeType: string): Promise<any> {
    //newStatus currently not used

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpUpgradeDowngradeList + "/items";
    var newItem = null;

    config.DEBUG && console.log("Modified item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": changeType,
        "IDZaproszenia": userItemData
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    config.DEBUG && console.log(response)
    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async changeOwnerFields(shareId: any, newOwner: string, newOwnerEmail: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareId + "/fields";
    var modifiedItem = null;
    var shareDateValue = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "NazwaOperatora": newOwner,
      "EmailOperatora": newOwnerEmail
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async deleteUserFromShare(shareUser: any): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpDeleteUserList + "/items";
    config.DEBUG && console.log(graphEndpoint);

    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "ObszarLookupId": shareUser.shareItemId,
        "NazwiskoImiePracownikaLookupId": shareUser.accountName,
        "RodzajUzytkownika": shareUser.userType,
        "NazwaUPN": shareUser.upn,
        "Title": shareUser.firstName,
        "Nazwisko": shareUser.lastName,
        "AdresEmail": shareUser.email
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;

  }

  async extendShare(shareData: any, shareDate: Date): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareData.id + "/fields";
    var modifiedItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "DataZamkniecia": shareDate
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async deleteShare(shareData: any): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpDeleteShareList + "/items";
    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "ObszarLookupId": shareData.id
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async expandShare(shareData: any, size: number): Promise<any> { //currently not used

    var headers = new Headers();
    //await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    //headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    //graphEndpoint needs to be changed before full deployment
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareData.id + "/fields";
    var modifiedItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    var options = {
      method: "POST",
      headers: headers,
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async generatePermList(shareData: any): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpGeneratePermList + "/items";
    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": shareData.Title,
        "URLobszaru": shareData.AdresObszaru,
        "IDGrupyOWP": shareData.IdentyfikatorGrupy
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  //method to get requestDirectAccess list item - top prevent multiple submits
  async getDirectAccessRequests(): Promise<any> {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var requestItems = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpRequestDirectAccessList + "/items?expand=fields"

    config.DEBUG && console.log("Calling: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    if (response.ok) {
      const jsonResponse = await response.json();
      requestItems = jsonResponse;
      return requestItems;
    } else {
      return null;
    }
  }

  //method to get requestDirectAccess list item - top prevent multiple submits
  async getRequestDirectAccessItem(shareData: any, userUPN: string): Promise<any> {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var requestItem = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpRequestDirectAccessList + "/items?expand=fields&filter=fields/OperatorEmail eq '" + userUPN + "' and fields/AdresObszaru eq '" + shareData.AdresObszaru + "'"

    config.DEBUG && console.log("Calling: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);
    if (response.ok) {
      const jsonResponse = await response.json();
      requestItem = jsonResponse;
      return requestItem;
    } else {
      return null;
    }
  }

  async requestDirectAccess(shareData: any, userUPN: string, justificationText: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpRequestDirectAccessList + "/items";
    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": shareData.Title,
        "AdresObszaru": shareData.AdresObszaru,
        "OperatorEmail": userUPN,
        "NazwaObszaru": shareData.Title,
        "Uzasadnienie": justificationText
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async setDirectAccessRequest(requestItemId: number, newStatus: string, comment: string): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpRequestDirectAccessList + "/items/" + requestItemId + "/fields";

    //itemId
    var modifiedItem = null;

    const newBody = {
      "Status": newStatus,
      "GodzinaZatwierdzenia": newStatus === "Zatwierdzony" ? new Date().toLocaleTimeString() : "",
      "Zatwierdzony": newStatus === "Zatwierdzony" ? true : false,
      "Komentarz": comment
    }
    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async SetAlert(shareData: any, userUPN: string, setAlert: string, inviteId: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpSetAlertList + "/items";
    var newItem = null;

    config.DEBUG && console.log("Create new item in: " + graphEndpoint);
    var newBody;

    if (shareData != null) { //for Operators setting alerts for them selfs
      newBody = {
        "fields": {
          "Title": shareData.Title,
          "IdentyfikatorGrupy": shareData.IdentyfikatorGrupy,
          "NazwaUPN": userUPN,
          "IdObszaru": shareData.IDObszaru,
          "ZmianaStanuAlertu": setAlert,
          "IDZaproszenia": inviteId
        }
      }
    } else { //for Operators setting alerts for others (PracownikNBP i Podmiot Zewnętrzny)
      newBody = {
        "fields": {
          "Title": "N/A",
          "IdentyfikatorGrupy": "N/A",
          "NazwaUPN": userUPN,
          "IdObszaru": "N/A",
          "ZmianaStanuAlertu": setAlert,
          "IDZaproszenia": inviteId
        }
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  async getAlert(groupId: string, userUPN: string): Promise<any> {
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var alertItem = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldUserUPN + " eq '" + userUPN + "'"

    config.DEBUG && console.log("Calling: " + graphEndpoint);
    try {
      const response = await fetch(graphEndpoint, options);
      const jsonResponse = response.json();
      config.DEBUG && console.log("Response received");
      //config.DEBUG && console.log(jsonResponse);
      alertItem = jsonResponse;
      return alertItem;
    } catch {
      return null;
    }
  }

  async SetAlertsToInProgress(inviteId: number): Promise<any> { //method used when changin alert

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    config.DEBUG && console.log("Current user ID: " + inviteId);
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items/" + inviteId + "/fields";

    //itemId
    var modifiedItem = null;

    const newBody = {
      "Alert": "2"
    }
    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async getShareListItem(groupId: string): Promise<any> {
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var shareItem = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items?expand=fields&filter=fields/IdentyfikatorGrupy eq '" + groupId + "'";

    config.DEBUG && console.log("Calling: " + graphEndpoint);
    try {
      const response = await fetch(graphEndpoint, options);
      const jsonResponse = response.json();
      config.DEBUG && console.log("Response received");
      //config.DEBUG && console.log(jsonResponse);
      shareItem = jsonResponse;
      return shareItem;
    } catch {
      return null;
    }
  }

  async getShareItemByTitle(title: string): Promise<any> {
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var shareItem = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items?expand=fields&filter=fields/Title eq '" + title + "'";

    config.DEBUG && console.log("Calling: " + graphEndpoint);
    try {
      const response = await fetch(graphEndpoint, options);
      const jsonResponse = response.json();
      config.DEBUG && console.log("Response received");
      //config.DEBUG && console.log(jsonResponse);
      shareItem = jsonResponse;
      return shareItem;
    } catch {
      return null;
    }
  }

  async getShareRequestItemByTitle(title: string): Promise<any> {
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var shareItem = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareRequests + "/items?expand=fields&filter=fields/Title eq '" + title + "'";

    config.DEBUG && console.log("Calling: " + graphEndpoint);
    try {
      const response = await fetch(graphEndpoint, options);
      const jsonResponse = response.json();
      config.DEBUG && console.log("Response received");
      //config.DEBUG && console.log(jsonResponse);
      shareItem = jsonResponse;
      return shareItem;
    } catch {
      return null;
    }
  }

  async getShareRequestListItem(): Promise<any> {
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })

    // config.DEBUG && console.log(bearer)
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    var shareRequestItem = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareRequests + "/items?expand=fields&filter=fields/StatusZadania eq 'W trakcie' or fields/StatusZadania eq 'Przygotowanie etykiety' or fields/StatusZadania eq 'Etykieta gotowa' or fields/StatusZadania eq 'Nowy'";

    //Cannot use below shorter condition, as IE do not understands polish character in OData - ń
    //var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpShareRequests + "/items?expand=fields&filter=fields/StatusZadania ne 'Ukończone poprawnie'";

    config.DEBUG && console.log("Calling: " + graphEndpoint);
    try {
      const response = await fetch(graphEndpoint, options);
      const jsonResponse = response.json();
      config.DEBUG && console.log("Share Requests Response received");

      shareRequestItem = jsonResponse;
      config.DEBUG && console.log(shareRequestItem);
      return shareRequestItem;
    } catch {
      return null;
    }
  }

  async setShareStatusToDelete(shareItemId: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareItemId + "/fields";

    //itemId
    var modifiedItem = null;

    const newBody = {
      "Usuwany": "tak"
    }
    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async getUsersFromAAD(fieldString: string): Promise<any> {

    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");
    var options = {
      method: "GET",
      headers: headers
    };

    var people = null;

    var graphEndpoint = "https://graph.microsoft.com/v1.0/users?$filter=startswith(surName, '" + fieldString + "') or startswith(givenName, '" + fieldString + "') or startswith(mail, '" + fieldString + "')";;

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    people = jsonResponse;
    return people;

  }

  async getShareUsersFromGroup(groupId: string) {
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };

    //var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "'";
    var graphEndpoint = "https://graph.microsoft.com/v1.0/groups/" + groupId + "/members";
    config.DEBUG && console.log("Getting users from owp group: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const users = await response.json();
      config.DEBUG && console.log(users);
      return users;
    } else {
      return null;
    }
  }

  async getShareUsersFromInvitations(groupId: string, userType: string) { //works
    var headers = new Headers();
    var bearer = ""
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers
    };
    if (userType === "Internal") {
      var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldShareType + " ne 'Podmiot zewnętrzny'";
    } else if (userType === "External") {
      var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldShareType + " eq 'Podmiot zewnętrzny'";
    } else {
      var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "'";
    }
    config.DEBUG && console.log("Getting users from owp group: " + graphEndpoint);

    const response = await fetch(graphEndpoint, options);

    if (response.ok) {
      const users = await response.json();
      //config.DEBUG && console.log(users);
      return users;
    } else {
      return null;
    }
  }

  async getSpecificUsersFromInvitations(groupId: string, userUPN: string, userType: string) {
    var headers = new Headers();
    var bearer = ""
    var requestCount = 0
    await this.GetToken().then(result => {
      bearer = "Bearer " + this._authToken;
    })
    headers.append("Authorization", bearer);
    headers.append("Retry-After", "2")
    var options = {
      method: "GET",
      headers: headers
    };

    do {
      if (userType == resource.str_UserTypeExternalKey) {
        //if external
        config.DEBUG && console.log("Searching for usertype: " + userType)
        var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldEmail + " eq '" + userUPN + "'";
      } else {
        //if internal
        config.DEBUG && console.log("Searching for usertype: " + userType)
        var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items?expand=fields&filter=fields/" + resource.str_FieldStaticGroupId + " eq '" + groupId + "' and fields/" + resource.str_FieldUserUPN + " eq '" + userUPN + "'";
      }

      config.DEBUG && console.log("Getting specific user from: " + graphEndpoint);

      const response = await fetch(graphEndpoint, options);
      config.DEBUG && console.log(response)
      if (response.ok) {
        const user = await response.json();
        requestCount++
        config.DEBUG && console.log("RequestCount raised to: " + requestCount)
        return user;
      } else if (response.status == 503 || response.status == 429) {
        setTimeout(() => {
          config.DEBUG && console.log("Timeout reached. Need to retry")
        }, 1000);
      } else {
        requestCount++
        config.DEBUG && console.log("RequestCount raised to: " + requestCount + " . Null result. No error.")
        return null;
      }
    }
    while (requestCount < 1)
  }

  async blockExternalUserOnList(userItemId: number, isBlocked: boolean): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    config.DEBUG && console.log("Current user ID: " + userItemId);
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items/" + userItemId + "/fields";

    //itemId
    var modifiedItem = null;

    const newBody = {
      "IsUserBlocked": isBlocked
    }
    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async setUserStatusToDelete(userItemId: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    config.DEBUG && console.log("Current user ID: " + userItemId);
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items/" + userItemId + "/fields";

    //itemId
    var modifiedItem = null;

    const newBody = {
      "Usuwany": "tak"
    }
    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async getExternalUserInAAD(userUPN: string): Promise<any> { //single user only

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");
    var options = {
      method: "GET",
      headers: headers
    };

    var user = null;
    config.DEBUG && console.log("Incoming UPN: " + userUPN);
    var graphEndpoint = "https://graph.microsoft.com/v1.0/users?$filter=mail eq '" + userUPN + "'&$select=accountEnabled,Id";

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    user = jsonResponse;
    config.DEBUG && console.log(user);
    return user;

  }

  async blockExternalUserInAAD(userID: string, isBlocked: boolean): Promise<any> {
    config.DEBUG && console.log(userID + " .. " + isBlocked)
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpBlockUserList + "/items";
    config.DEBUG && console.log(graphEndpoint);
    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "Title": "Blokowanie",
        "UserId": userID,
        "AccountEnabled": isBlocked
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    return jsonResponse;
  }

  async getUserInAAD(userUPN: string): Promise<any> { //single user only

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");
    var options = {
      method: "GET",
      headers: headers
    };

    var user = null;
    var graphEndpoint = "https://graph.microsoft.com/v1.0/users/" + userUPN;

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    user = jsonResponse;
    config.DEBUG && console.log(user);
    return user;

  }

  async removeOperatorFromUserList(userItemId: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    config.DEBUG && console.log("Current user ID: " + userItemId);
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.invitationsListId + "/items/" + userItemId;

    var options = {
      method: "DELETE",
      headers: headers
    };

    const response = await fetch(graphEndpoint, options);
    return response;
    //https://graph.microsoft.com/v1.0/sites/{site-id}/lists/{list-id}/items/{item-id}
  }

  async getDelayedStatusChangeItem(groupId: number): Promise<any> {
    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var requestItem = null;
    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpDelayStatusChangeList + "/items?expand=fields&filter=fields/GroupId eq '" + groupId + "'"

    var options = {
      method: "GET",
      headers: headers
    };

    const response = await fetch(graphEndpoint, options);
    const jsonResponse = response.json();
    requestItem = jsonResponse;
    return requestItem;
  }

  async removeDelayedStatusChange(itemId: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpDelayStatusChangeList + "/items/" + itemId

    var options = {
      method: "DELETE",
      headers: headers
    };

    const response = await fetch(graphEndpoint, options);
    return response;
  }

  async restoreShareInProgress(shareItemId: any): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.sharesListId + "/items/" + shareItemId + "/fields";
    var modifiedItem = null;

    config.DEBUG && console.log("Modify item in: " + graphEndpoint);

    const newBody = {
      "Wygaszony": "2"
    }

    var options = {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    modifiedItem = jsonResponse;
    return modifiedItem;
  }

  async restoreShare(groupId: any, userUPN: string, shareItemId: number): Promise<any> {

    var headers = new Headers();
    await this.GetToken();
    var bearer = "Bearer " + this._authToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "application/json");

    var graphEndpoint = "https://graph.microsoft.com/v1.0/sites/" + config.exfsRootSiteId + "/lists/" + config.swpRestoreShareList + "/items";
    var newItem = null;
    
    config.DEBUG && console.log("Create new item in: " + graphEndpoint);

    const newBody = {
      "fields": {
        "IdentyfikatorGrupy": groupId,
        "NazwaUPN": userUPN,
        "IdElementuObszaru": shareItemId
      }
    }

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(newBody)
    };

    const response = await fetch(graphEndpoint, options);

    const jsonResponse = response.json();
    newItem = jsonResponse;
    return newItem;
  }

  //#endregion
}