import * as React from "react";
const config = require("./config.json");
const resource = require("../LangFiles/resource.json");

export class Footer extends React.Component<{}> {

    // Function to get the current year
    getCurrentYear() {
        return new Date().getFullYear();
    }

    render() {
        return (
            <footer>
                <div className="text-center" style={{fontSize: '12px', marginTop: '80px', padding: '5px'}}>
                    ©Copyright {this.getCurrentYear()}: 
                    <a href="https://nbp.pl"> Narodowy Bank Polski</a>
                </div>
                <div className="text-center" style={{fontSize: '12px', marginBottom: '20px', padding: '5px'}}>
                    {resource.str_AppVersionString} {config.appVersion}
                </div>
            </footer>
        );
    }
}