//------------------------------------ Description ------------------------------------------------------------
//This form allows Operators to generate full list of users having access to share.
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import {Modal} from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { 
    PrimaryButton, 
    Stack,
    DefaultButton, 
    IStackTokens,
    Label
 } from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");

const stackTokens: IStackTokens = { childrenGap: 10 };

export interface GeneratePermListFormProps { 
    isOpen:boolean ;
    authToken:string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    shareDetailsRefreshMethod: any;
    refreshAfterDeleteMethod: any;
    lang:string;
}
export interface GeneratePermListFormState {
    userTypeValue?:string;
    showGeneratePermListModal:boolean;
    GeneratePermListButtonDisabled: boolean;
    shareName: string;
    newShareClosureDate?: Date;
 }

export class GeneratePermListForm extends React.Component<GeneratePermListFormProps, GeneratePermListFormState> {
    private _GeneratePermListTitleId: string = getId('new-folder-title');
    private _GeneratePermListSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: GeneratePermListFormProps) {
        super(props);

        this.state = {
            showGeneratePermListModal:false,
            GeneratePermListButtonDisabled:true,
            shareName: ""
        }
    }


    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showGeneratePermListModal} = this.state;

        return(
        <Modal
            titleAriaId={this._GeneratePermListTitleId}
            subtitleAriaId={this._GeneratePermListSubtitleId}
            isOpen={showGeneratePermListModal}
            onDismiss={this._closeGeneratePermListModal.bind(this)}
            isModeless={false}
            dragOptions={undefined}
            containerClassName="swpStyle-narrow-forms-modal-window"
            theme={colorThemeLight}
        >
            <div className="swpStyle-upload-file-modal-header">
                <span id={this._GeneratePermListTitleId}><h6>{resource.str_HeaderGeneratePermList}</h6></span>
            </div>
            <div id={this._GeneratePermListSubtitleId} className="swpStyle-upload-file-modal-body">
                <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{resource.str_MsgGeneratePermList}</p></Label>
                <div id="swpStyle-del-item-buttons">
                    <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                        <PrimaryButton theme={colorThemeLight} onClick={this._onGeneratePermList.bind(this)} text={resource.str_BtnConfirm}/>
                        <DefaultButton theme={colorThemeLight} onClick={this._closeGeneratePermListModal.bind(this)} text={resource.str_BtnCancel} />
                    </Stack>
                </div>
            </div>
        </Modal>
        )
    }

    private _closeGeneratePermListModal= (): void => {
        this.setState({ 
            showGeneratePermListModal: false,
        });
    }

    public _openGeneratePermListModal() {
        this.setState({ showGeneratePermListModal: true });
    }
  
    private _onGeneratePermList() {
        config.DEBUG && console.log("_onGeneratePermListType");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        graphService.generatePermList(this.props.currentShareData).then(result => {
            //
            if(result){
              this._closeGeneratePermListModal();
            }
        })       
    }
    
}