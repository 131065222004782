//------------------------------------ Description ------------------------------------------------------------
//This form allows to delete share from SWP system
//-------------------------------------------------------------------------------------------------------------

import * as React from "react";
import {Modal} from 'office-ui-fabric-react/lib/Modal';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { 
    PrimaryButton, 
    Stack, 
    DefaultButton, 
    IStackTokens, 
    Label,
    Dialog,
    DialogFooter,
    DialogType
 } from 'office-ui-fabric-react';
import { GraphService } from "../components/GraphService";
import * as Msal from "msal";
import { colorThemeLight } from "../components/Themes";
import { ProgressIndicator, IProgressIndicatorStyles } from 'office-ui-fabric-react/lib/ProgressIndicator';

const config = require("../components/config.json");
// const resource = require("../LangFiles/resource.json");
const gblResourcePL = require("../LangFiles/resourcePL.json");

const progressStyles: Partial<IProgressIndicatorStyles> = {
    itemProgress : { paddingTop: 0 }
  };

const stackTokens: IStackTokens = { childrenGap: 10 };

const dialogContentProps = {
    type: DialogType.normal,
    title: "Sukces",
    closeButtonAriaLabel: 'Zamknij',
    subText: gblResourcePL.str_LblCreateShareConfirm
};

const dialogModalProps = {
    isBlocking: true,
    styles: { main: { minWidth: 250, minHeight: 50 } }
};

export interface DeleteShareFormProps { 
    isOpen:boolean ;
    authToken:string;
    userAgentApplication: Msal.UserAgentApplication;
    currentShareData: any;
    shareDetailsRefreshMethod: any;
    refreshAfterDeleteMethod: any;
    lang:string;
}
export interface DeleteShareFormState {
    userTypeValue?:string;
    showDeleteShareModal:boolean;
    DeleteShareButtonDisabled: boolean;
    shareName: string;
    newShareClosureDate?: Date;
    contentLoad:boolean;
    showConfirmDialog: boolean;
 }

export class DeleteShareForm extends React.Component<DeleteShareFormProps, DeleteShareFormState> {
    private _DeleteShareTitleId: string = getId('new-folder-title');
    private _DeleteShareSubtitleId: string = getId('new-folder-subtitle');

    constructor(props: DeleteShareFormProps) {
        super(props);

        this.state = {
            showDeleteShareModal:false,
            DeleteShareButtonDisabled:true,
            shareName: "",
            contentLoad:false,
            showConfirmDialog: true
        }
    }


    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        const { showDeleteShareModal, contentLoad, showConfirmDialog, DeleteShareButtonDisabled} = this.state;

        return(
        <Modal
            titleAriaId={this._DeleteShareTitleId}
            subtitleAriaId={this._DeleteShareSubtitleId}
            isOpen={showDeleteShareModal}
            onDismiss={this._closeDeleteShareModal.bind(this)}
            isModeless={false}
            dragOptions={undefined}
            containerClassName="swpStyle-narrow-forms-modal-window"
            theme={colorThemeLight}
        >
            <div className="swpStyle-upload-file-modal-header">
                <span id={this._DeleteShareTitleId}><h6>{resource.str_HeaderDeleteShare}</h6></span>
            </div>
            <ProgressIndicator progressHidden={!contentLoad} barHeight={5} theme={colorThemeLight} styles={progressStyles}/>
            <div id={this._DeleteShareSubtitleId} className="swpStyle-upload-file-modal-body">               
                <Label theme={colorThemeLight} style={{fontWeight: 400, textAlign: "center", marginLeft: "auto", marginRight: "auto"}}><p>{resource.str_MsgDeleteShareWarning}</p></Label>
                {/* Submit buttons: */}
                <Stack horizontal tokens={stackTokens} className="swpStyle-modal-buttonParrent">
                    <PrimaryButton disabled={contentLoad} theme={colorThemeLight} onClick={this._onDeleteShare.bind(this)} text={resource.str_BtnConfirm} />
                    <DefaultButton theme={colorThemeLight} onClick={this._closeDialog} text={resource.str_BtnCancel} />
                </Stack>

                <Dialog 
                    hidden={showConfirmDialog} 
                    // title={this.state.lang.str_LblCreateShareConfirm} 
                    onDismiss={this._closeDialog} 
                    dialogContentProps={dialogContentProps} 
                    modalProps={dialogModalProps}
                    >
                    <DialogFooter>
                        <PrimaryButton theme={colorThemeLight} onClick={this._closeDeleteShareModal} text={resource.str_BtnOK} />
                    </DialogFooter>
                </Dialog>

            </div>
        </Modal>
        )
    }

    private _closeDeleteShareModal= (): void => {
        config.DEBUG && console.log("_closeNewFolderModal");
        this.setState({ 
            showDeleteShareModal: false,
            showConfirmDialog: true
        });
        this.props.refreshAfterDeleteMethod();
    }

    private _showDialog = (): void => {
        this.setState({ 
            showConfirmDialog: false
         });
    }

    private _closeDialog = (): void => {
        this.setState({ 
            showDeleteShareModal: false
         });
    }

    public _openDeleteShareModal() {
        this.setState({ showDeleteShareModal: true });
    }
  
    private _onDeleteShare() {
        config.DEBUG && console.log("_onDeleteShareType");
        let graphService: GraphService = new GraphService(this.props.authToken, this.props.userAgentApplication);
        this.setState({ //start load
            contentLoad: true,
            DeleteShareButtonDisabled:true,
        }) 
        graphService.setShareStatusToDelete(this.props.currentShareData.id).then(result =>{
            graphService.deleteShare(this.props.currentShareData).then(result => {
                //
                if(result){
                this._showDialog();
                // this.props.refreshAfterDeleteMethod();
                }
            })
        })
        this.setState({contentLoad: false}) //finish load       
    }
    
}