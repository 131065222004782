import * as React from "react";
import Table from 'react-bootstrap/Table';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export interface LoginPaneProps { loginUser():void, lang:string };
export interface LoginPaneState { isAuthenticated: boolean, userName: string, visible: boolean};

export class LoginPane extends React.Component<LoginPaneProps, LoginPaneState> {
    constructor(props: LoginPaneProps) {
        super(props);
        this.state = { 
            isAuthenticated: false,
            userName: "",
            visible: false
        };
    }

    render() {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        return (
            <Container>
                <Row>
                    <Col style={{paddingTop:"50px"}}>
                        <div className="card text-center m-4">
                            <h5 className="card-header">{resource.str_HeaderLoginRequired}</h5>
                            <div className="card-body">
                                <h6 className="card-title">{resource.str_MsgNeedToLoginToAccess}</h6>
                                <Button type="button" variant="secondary" onClick={() => this.props.loginUser()}>{resource.str_BtnLogIn}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}