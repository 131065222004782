import * as React from 'react';
import { CommandButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import { IIconProps } from 'office-ui-fabric-react';
import { colorThemeLight } from './Themes';

const addFriendIcon: IIconProps = { iconName: 'AddFriend' };
const permissionsIcon: IIconProps = { iconName: 'ContactCardSettings' };
const permsListIcon: IIconProps = { iconName: 'ContactList' };
const requestAccessIcon: IIconProps = { iconName: 'FileRequest' };
const disableIcon: IIconProps = { iconName: 'PlugConnected' };
const enableIcon: IIconProps = { iconName: 'PlugDisconnected' };
const extendIcon: IIconProps = { iconName: 'PublicCalendar' };
const sizeIcon: IIconProps = { iconName: 'MiniExpand' };
const deleteIcon: IIconProps = { iconName: 'Delete' };
const disableAlertIcon: IIconProps = { iconName: 'RingerOff' };
const enableAlertIcon: IIconProps = { iconName: 'Ringer' };
const refreshIcon: IIconProps = { iconName: 'Refresh'};
const renameIcon: IIconProps = { iconName: 'Rename' };

export interface ShareMenuProps {
    isLimited: boolean,
    isLimitedValue: string,
    isReadOnly: string,
    currentItem: any,
    newUserOnClickMethod: any,
    modifyUsersMethod: any,
    generatePermListMethod: any,
    requestDirectAccessMethod: any,
    changeStatusOnClickMethod: any,
    deleteShareMethod: any,
    extendShareMethod: any,
    expandShareMethod: any,
    renameShareMethod: any,
    refreshMethod: any,
    setAlertListMethod: any,
    lang: string,
    shareStateLang: string,
    userAlertState: number,
    directAccessInProgress: boolean
}


export class ShareMenu extends React.Component<ShareMenuProps> {
    constructor(props: ShareMenuProps) {
        super(props);
        this.state = {
            showCallout: false
        };
    };



    public render(): JSX.Element {

        //*** Language section ***
        let resource: any;
        if(this.props.lang == "PL"){
            resource = require("../LangFiles/resourcePL.json");
        } else {
            resource = require("../LangFiles/resource.json");
        }
        //*** end of language section ***

        let typeButtonDissabled, statusButtonDissabled, buttonsForDisabledShare, statusChangeInProgress, expandButtonDissabled, setAlertBtnDisabled, renameButtonDisabled = false
        let alertText = ""
        if(this.props.currentItem.StatusObszaru != resource.str_ShareStatusEnabled && this.props.currentItem.StatusObszaru != resource.str_ShareStatusDisabled ){
            statusButtonDissabled = true;
            statusChangeInProgress = true;
            expandButtonDissabled = true;
        }

        if(this.props.userAlertState == 2 ){ //Alert modification in progress
            setAlertBtnDisabled = true;
            alertText = resource.str_MenuAlertInProgress
        } else if (this.props.userAlertState == 1){ //Alert is enabled for current user
            setAlertBtnDisabled = false;
            alertText = resource.str_MenuDisableAlert
        } else { //0 or null, alerts disabled.
            setAlertBtnDisabled = false;
            alertText = resource.str_MenuEnableAlert
        }

        //checking for change size in progress:
        if(this.props.currentItem.ZmianaRozmiaru == true){ //Regional string
            expandButtonDissabled = true;
        }

        //checking for renaming in progress:
        if(this.props.currentItem.ZmianaNazwy == true){
            renameButtonDisabled = true;
        }

        //checking if Direct Access in progress:
        //...

        return (
            <div>
                <CommandButton
                    role="menuitem"
                    title={resource.str_PopActions}
                    theme={colorThemeLight}
                    iconProps={{ iconName: 'Settings' }}
                    text={resource.str_BtnShareActions}
                    checked={null}
                    menuProps={{
                        shouldFocusOnMount: false,
                        directionalHint: 6,
                        theme: colorThemeLight,
                        className: 'swpStyle-context-menu',
                        items: [
                            {
                                key: 'addUser',
                                text: resource.str_NewUserModalHeader,
                                onClick: this.props.newUserOnClickMethod,
                                iconProps: addFriendIcon
                            },
                            {
                                key: 'viewUsers',
                                text: resource.str_BtnViewAllUsers,
                                onClick: this.props.modifyUsersMethod,
                                iconProps: permissionsIcon
                            },
                            {
                                key: 'generateUserList',
                                text: resource.str_MenuGenerateList,
                                onClick: this.props.generatePermListMethod,
                                iconProps: permsListIcon
                            },
                            {
                                key: 'requestDirectAccess',
                                text: resource.str_MenuRequestDirectAccess,
                                onClick: this.props.requestDirectAccessMethod,
                                disabled: this.props.directAccessInProgress,
                                iconProps: requestAccessIcon
                            },
                            {
                                key: 'alert',
                                text: alertText,
                                onClick: this.props.setAlertListMethod,
                                disabled: setAlertBtnDisabled,
                                iconProps: this.props.userAlertState == 0 ? enableAlertIcon : disableAlertIcon
                            },
                            {
                                key: 'divider_1',
                                itemType: ContextualMenuItemType.Divider
                            },
                            {
                                key: 'toReadOnly',
                                text: statusChangeInProgress ? resource.str_FieldValueShareChangeInProgress : resource.str_MenuChangeStatusTo + this.props.shareStateLang,
                                onClick: this.props.changeStatusOnClickMethod,
                                disabled: statusButtonDissabled,
                                iconProps: statusButtonDissabled ? disableIcon : enableIcon
                            },
                            {
                                key: 'closureTime',
                                name: resource.str_MenuExtendShare,
                                onClick: this.props.extendShareMethod,
                                disabled: typeButtonDissabled || buttonsForDisabledShare,
                                iconProps: extendIcon
                            },
                            {
                                key: 'expand',
                                name: resource.str_MenuExpandShare,
                                onClick: this.props.expandShareMethod,
                                disabled: expandButtonDissabled,
                                iconProps: sizeIcon
                            },
                            {
                                key: 'rename',
                                name: resource.str_MenuRenameShare,
                                onClick: this.props.renameShareMethod,
                                disabled: renameButtonDisabled,
                                iconProps: renameIcon
                            },
                            {
                                key: 'delete',
                                text: resource.str_MenuDeleteShare,
                                onClick: this.props.deleteShareMethod,
                                iconProps: deleteIcon
                            },
                            {
                                key: 'divider_2',
                                itemType: ContextualMenuItemType.Divider
                            },
                            {
                                key: 'refresh',
                                text: resource.str_MenuRefresh,
                                onClick: this.props.refreshMethod,
                                iconProps: refreshIcon
                            }
                        ]
                    }}
                />
            </div>
        );
    
    }
}